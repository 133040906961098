import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAppSelector,useAppDispatch } from "../../../redux/hooks";
import { toggleSideNav } from "../../../redux/slices/sidebarExpansionSlice";
import Icon from "../Icon/Icon";
import styles from "./MainSidebar.module.scss";
import config from "../../../config/config";
import ExpandIconDark from "../../../assets/images/DarkTheme/expand_icon.svg";
import ExpandIconLight from "../../../assets/images/LightTheme/expand_icon.svg";
import { RootState } from "../../../types/types";
import { useTranslation } from "react-i18next";
import ThemeToggleBtn from "../ThemeToggleBtn/ThemeToggle";
import LogoutIconDark from "../../../assets/images/DarkTheme/logout_icon.svg";
import LogoutIconLight from "../../../assets/images/LightTheme/logout_icon.svg";
import axiosInstance from "../../../config/interceptors/axios.interceptor";
import { QUERY_STATUS, countryMappings } from "../../../constants/dataConstants";
import authService from "../../../config/auth.service";
import { HttpStatusCode } from "axios";

type MainSidebarProps = {
  isLoginPage?: boolean;
};

const MainSidebar: React.FC<MainSidebarProps> = ({isLoginPage= false }) => {
  const { t } = useTranslation();
  const currentTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  const userDetails: any = useAppSelector(
    (state: any) => state.userDetails.user
  );
  const [isSelectedItem, setIsSelectedItem] = useState("");
  const ExpandIcon =
    currentTheme === "light" ? ExpandIconLight : ExpandIconDark;
  const logoutIcon =
    currentTheme === "light" ? LogoutIconLight : LogoutIconDark;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isExpanded = useAppSelector((state) => state.sideNavExpansion.isExpanded);

  useEffect(() => {
    if (isSelectedItem === "") {
      setIsSelectedItem(location.pathname || "/myaccount");
    }
  }, [isSelectedItem, location.pathname]);

  const handleLinkClick = (e: React.MouseEvent, path: string) => {
    e.preventDefault();
    if (location.pathname === path) {
      navigate(path, { replace: true });
      window.location.reload();
    } else {
      navigate(path);
    }
    setIsSelectedItem(path);
  };
  const getNavItemColor = (
    path: string,
    selectedPath: string,
    theme: string
  ) => {
    if (path === selectedPath) {
      return theme === "light" ? "#5969d6" : "#99B2F5";
    } else if (selectedPath === "" && path === "/myaccount") {
      return theme === "light" ? "#5969d6" : "#99B2F5";
    }
    return theme === "light" ? "#707070" : "#AEAEB2";
  };

  const sideNavHandler = () => {
    dispatch(toggleSideNav());
  };
  const handleClickLogout = () => {
    const url = `${config.appUrl}/auth/logout`;
    axiosInstance
      .get(url)
      .then((res: any) => {
        if (res && res.data) {
          let result = JSON.parse(JSON.stringify(res.data));
          if (result && result.statusCode === HttpStatusCode.Ok) {
            if (result.head && result.head.authentication) {
              const auth = result.head.authentication;
              if (
                auth.loginSessionStatus === QUERY_STATUS.LOGOUT &&
                auth.userSessionStatus === QUERY_STATUS.LOGOUT
              ) {
                authService.clearAuth();
                window?.heap?.resetIdentity();
                window.location.href = "/login";
                return;
              }
            }
          }
        }
        // authService.clearAuth();
        // window.location.href = "/login";
      })
      .catch((err: any) => {
        console.log(`Logout error: ${err}`);
        authService.clearAuth();
        window?.heap?.resetIdentity();
        window.location.href = "/login";
      });
  };

  return (
    <div
      className={`main_sidebar ${styles.main_sidebar} ${isExpanded && "expanded"} ${isExpanded && styles.expanded}`}
    >
      <div className={`bg_gradient ${styles.bg_gradient}`}></div>
      <div className={styles.top_nav_section}>
        <Link to="/myaccount" onClick={(e) => handleLinkClick(e, "/myaccount")}>
          <Icon
            name="amway_a_logo"
            size={41}
            color={currentTheme === "light" ? "#2C2C2C" : "#FFFFFF"}
            className={styles.amway_logo}
          />
          {isExpanded && (
            <span
              className={styles.logo_title}
              style={{ color: currentTheme === "dark" ? "#D8D8D9" : "#2C2C2C" }}
            >
              AM Workspace
            </span>
          )}
        </Link>
        <div
          onClick={sideNavHandler}
          className={`${styles.toggle_button} ${isExpanded && styles.toggle_position}`}
        >
          <div
            className={`sidenav_btn ${styles.sidenav_btn} ${isExpanded && styles.expanded_btn}`}
          >
            <img src={ExpandIcon} alt="<>" />
          </div>
        </div>
      </div>
      {!isLoginPage &&
      <>
        <nav className={styles.nav}>
          <ul className={styles.nav_list}>
            <li
              className={`nav_item ${styles.nav_item} ${isSelectedItem === "/myaccount" && "selected_nav_item"}`}
              onClick={(e) => handleLinkClick(e, "/myaccount")}
            >
              <Link to="/myaccount">
                <Icon
                  name="account_list"
                  size={20}
                  color={getNavItemColor(
                    "/myaccount",
                    isSelectedItem,
                    currentTheme
                  )}
                  className={styles.link_icon}
                />
                {isExpanded && (
                  <span className={`side_nav_item ${styles.side_nav_item}`}>
                    {t("my_account")}
                  </span>
                )}
              </Link>
            </li>
            {userDetails?.isAccessibleHATVFeature && (
              <li
                className={`nav_item ${styles.nav_item} ${isSelectedItem === "/hatv" && "selected_nav_item"}`}
                onClick={(e) => handleLinkClick(e, "/hatv")}
              >
                <Link to="/hatv">
                  <Icon
                    name="hatv_icon"
                    size={23}
                    color={getNavItemColor("/hatv", isSelectedItem, currentTheme)}
                    className={styles.link_icon}
                  />
                  {isExpanded && (
                    <span className={`side_nav_item ${styles.side_nav_item}`}>
                      {t("hatv")}
                    </span>
                  )}
                </Link>
              </li>
            )}
            {userDetails?.isAccessibleReportBuilderFeature && (
              <li
                className={`nav_item ${styles.nav_item} ${isSelectedItem === "/report" && "selected_nav_item"}`}
                onClick={(e) => handleLinkClick(e, "/report")}
              >
                <Link to="/report">
                  <Icon
                    name="report_icon"
                    size={21}
                    color={getNavItemColor(
                      "/report",
                      isSelectedItem,
                      currentTheme
                    )}
                    className={styles.link_icon}
                  />
                  {isExpanded && (
                    <span className={`side_nav_item ${styles.side_nav_item}`}>
                      {t("reports")}
                    </span>
                  )}
                </Link>
              </li>
            )}

            {config?.isAPFeatureEnabled && userDetails?.isAccessibleAPFeature && (
              <li
                className={`nav_item ${styles.nav_item} ${isSelectedItem === "/ap" && "selected_nav_item"}`}
                onClick={(e) => handleLinkClick(e, "/ap")}
              >
                <Link to="/ap">
                  <Icon
                    name="ap_icon"
                    size={19}
                    color={getNavItemColor("/ap", isSelectedItem, currentTheme)}
                    className={styles.link_icon}
                  />
                  {isExpanded && (
                    <span className={`side_nav_item ${styles.side_nav_item}`}>
                      {t("AP_info.ap")}
                    </span>
                  )}
                </Link>
              </li>
            )}
          </ul>
        </nav>
        <div className={styles.navlist_bottom}>
          <div
            className={`logout_btn ${styles.logout_btn}`}
            onClick={handleClickLogout}
            data-testid="logout_btn"
          >
            <span className={styles.logout_txt}>{t("logout")}</span>
            <img src={logoutIcon} alt="Logout" className={styles.logout_img} />
          </div>
          <div className={styles.toggle_switch_wrapper}>
            <ThemeToggleBtn isSidenav ={true} isExpanded={isExpanded}/>
          </div>
        </div>
      </>
      }

    </div>
  );
};

export default MainSidebar;
