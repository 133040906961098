import React from "react";
import styles from "./LoginHeader.module.scss";
import Icon from "../../common/Icon/Icon";
import { useAppSelector } from "../../../redux/hooks";

const LoginHeader: React.FC = () => {
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  return (
    <header className={`login_header ${styles.login_header}`}>
      <Icon
        name="login_header"
        size={41}
        color={currentTheme === "light" ? "#2C2C2C" : "#D8D8D9"}
        className={styles.amway_logo}
      />
    </header>
  );
};

export default LoginHeader;
