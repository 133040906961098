import React, { useState, useEffect, useRef } from "react";
import Tag from "../Tagtabs/Tagtabs";
import styles from "./TagDropdown.module.scss";
import { useAppSelector } from "../../../redux/hooks";
import dropdownArrowLight from "../../../assets/images/LightTheme/down_arrow_light.svg";
import dropdownArrowDark from "../../../assets/images/DarkTheme/down_arrow_dark.svg";
import Button from "../Button/Button";
import Checkbox from "../CustomCheckbox/CustomCheckbox";
import { RootState } from "../../../redux/store";

interface TagDropdownProps {
  getTags: string[];
  onChangeTags: (selectedTag: string[]) => void;
  selectedTags?: string[];
  updatedNoteTags?: string[];
}

const TagDropdown: React.FC<TagDropdownProps> = ({
  getTags,
  onChangeTags,
  selectedTags,
  updatedNoteTags,
}) => {
  const [filteredTags, setFilteredTags] = useState<string[]>(
    selectedTags || []
  );
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const iconRef = useRef<HTMLImageElement | null>(null); 

  const currentTheme: any = useAppSelector(
    (state: any) => state.theme.currentTheme
  );
  const isExpanded = useAppSelector((state) => state.sideNavExpansion.isExpanded);
  const dropdownArrow =
    currentTheme === "light" ? dropdownArrowLight : dropdownArrowDark;

  const [selectedTag, setSelectedTag] = useState<string[]>(selectedTags || []);
  const [newTags, setNewTags] = useState<string[]>([]);

  useEffect(() => {
    if (selectedTags) {
      setSelectedTag(selectedTags);
      setFilteredTags(selectedTags);
    } else if (updatedNoteTags) {
      setSelectedTag(updatedNoteTags);
      setFilteredTags(updatedNoteTags);
    }
  }, [selectedTags, updatedNoteTags]);

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  const selectTagHandler = (tag: string) => {
    const updatedSelectedTag = selectedTag.includes(tag)
      ? selectedTag.filter((selectedTag) => selectedTag !== tag)
      : [...selectedTag, tag];

    setSelectedTag(updatedSelectedTag);
    const newTagList = updatedSelectedTag.filter(
      (selectedTag) => !filteredTags.includes(selectedTag)
    );
    setNewTags(newTagList);
  };

  const addTagHandler = () => {
    if (newTags?.length > 0) {
      const updatedTags = [...filteredTags, ...newTags];
      onChangeTags(updatedTags);
      setFilteredTags(updatedTags);
      setIsOpen(false);
      setNewTags([]);
    }
  };

  const handleDeleteTag = (tagToDelete: string) => {
    const updatedTags = filteredTags.filter((tag) => tag !== tagToDelete);
    setFilteredTags(updatedTags);
    setSelectedTag(updatedTags);
    onChangeTags(updatedTags);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isOpen &&
        dropdownRef.current &&
        iconRef.current &&
        !dropdownRef.current.contains(event.target as Node) 
        && !iconRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);
  
  return (
    <div className={`tag_dropdown_wrapper  ${styles.tag_dropdown_wrapper} ${isExpanded && styles.isExpanded}`}>
      <div className={styles.tag_input_wrapper}>
        <div ref={dropdownRef} className={`${styles.input_wrapper}`}>
          <input
            type="text"
            placeholder={"Select one or more tags"}
            onFocus={() => setIsOpen(true)}
            readOnly
            className={`tag_input ${styles.tag_input}`}
          />
          <img
            ref={iconRef}
            src={dropdownArrow}
            alt="Downkey"
            className={`${styles.arrow_icon} ${isOpen ? styles.rotate : ""}`}
            onClick={toggleDropdown}
          />
        </div>
        <div className={styles.add_tag}>
          <Button
            label={"Add Tag"}
            onClick={addTagHandler}
            style="filled"
            isIcon={true}
            iconName={
              newTags?.length === 0 ? "plus_disabled_light" : "plus_white"
            }
            iconSize={12}
            isDisabled={newTags?.length === 0}
            themeColor={currentTheme === "light" ? "#fff" : "#000"}
            disabledThemeColor={currentTheme === "light" ? "#5e5e5e" : "#8E8E93"}
          />
        </div>
      </div>
      {isOpen && (
        <div ref={dropdownRef} className={`dropdown_menu ${styles.dropdown_menu}`}>
          {getTags.map((tag, index) => (
              <div key={index} className={`dropdown_item ${styles.dropdown_item} ${selectedTag?.includes(tag) ? `checked ${styles.checked }`: ''}`}>
              <div className={styles.checkbox_wrapper} key={index}>
                <Checkbox
                  checked={selectedTag?.includes(tag)}
                  label={tag}
                  checkboxHandler={() => selectTagHandler(tag)}
                  isDisabled={false}
                  isTagCheck={true}
                />
              </div>
            </div>
          ))}
        </div>
      )}
      <div className={styles.tab_container}>
        {filteredTags?.map((tag, index) => (
          <Tag key={index} label={tag} onDelete={() => handleDeleteTag(tag)} />
        ))}
      </div>
    </div>
  );
};

export default TagDropdown;
