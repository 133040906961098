import { SideNavType } from "../types/types";

export const LANGUAGES = [
  { label: "English", code: "en" },
  { label: "Thai", code: "th" },
];

export const SUPPORTED_LANG = ["en", "th"];

export const SIDE_NAV_LINKS: SideNavType[] = [
  {
    mainTabID: "abo_growth_companion",
    mainTabName: "ABO Growth Companion",
    innerTabs: [
      // {
      //   tabId: "business_performance",
      //   tabName: "Business Performance",
      //   isDisabled: false,
      // },
      // {
      //   tabId: "key_kpis",
      //   tabName: "Key KPIs",
      //   isDisabled: true,
      // },
      {
        tabId: "health_and_wellbeing",
        tabName: "Health & Wellbeing",
        isDisabled: true,
      },
      {
        tabId: "pipeline",
        tabName: "pipeline",
        isDisabled: false,
      },
      {
        tabId: "qualifications",
        tabName: "qualifications",
        isDisabled: false,
      },
      {
        tabId: "structure",
        tabName: "structure",
        isDisabled: false,
      },
      {
        tabId: "income",
        tabName: "income",
        isDisabled: false,
      }
      // {
      //   tabId: "probability_modeling",
      //   tabName: "Probability Modeling",
      //   isDisabled: false,
      // },
      // {
      //   tabId: "compensation",
      //   tabName: "Compensation",
      //   isDisabled: false,
      // },
    ],
  },
  {
    mainTabID: "goals_and_plans",
    mainTabName: "Goals & Action Plans",
    innerTabs: [
      {
        tabId: "goals",
        tabName: "Goals",
        isDisabled: false,
      },
      {
        tabId: "documents",
        tabName: "Documents",
        isDisabled: false,
      },
      // {
      //   tabId: "notes",
      //   tabName: "Progress Notes",
      //   isDisabled: false,
      // },
    ],
  },
  {
    mainTabID: "notes",
    mainTabName: "Goals & Action Plans",
    innerTabs: [
      {
        tabId: "notes",
        tabName: "Progress Notes",
        isDisabled: false,
      },
    ],
  },
  {
    mainTabID: "personal_information",
    mainTabName: "Personal Information",
    innerTabs: [
      {
        tabId: "contact_information",
        tabName: "Contact Information",
        isDisabled: false,
      },
      {
        tabId: "IBO_background",
        tabName: "IBO Background",
        isDisabled: false,
      },
      {
        tabId: "succession_plan",
        tabName: "Succession Plan",
        isDisabled: false,
      },
    ],
  },
];

export const AP_SIDE_NAV_LINKS: SideNavType[] = [
  {
    mainTabID: "ap_information",
    mainTabName: "AP Information",
    innerTabs: [
      {
        tabId: "background",
        tabName: "Background",
      },
      {
        tabId: "notes",
        tabName: "Notes",
      },
      {
        tabId: "documents",
        tabName: "Documents",
      }
    ],
  }
];

export const ALLOWED_FILE_TYPES = [
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-powerpoint",
];

export const QUERY_STATUS = {
  ACTIVE: 1,
  EXPIRED: 2,
  SUSPENDED: 2,
  REVOKED: 3,
  LOGOUT: 9,
  INACTIVE: 9,
};

export const localeDateMappings: {
  [key: string]: {
    dateFormat: string;
    longDateFormat: string;
    dateTimeFormat: string;
  };
} = {
  ANA: {
    dateFormat: "MM/dd/yyyy",
    longDateFormat: "MMM dd, yyyy",
    dateTimeFormat: "MM/dd/yy h:mma",
  },
  THA: {
    dateFormat: "dd/MM/yyyy",
    longDateFormat: "dd MMM yyyy",
    dateTimeFormat: "dd/MM/yy h:mma",
  },
  // Add more mappings as needed
};

export const countryCodeMappings: { [key: string]: string } = {
  "010": "ANA",
  "200": "THA",
  "020": "CAN",
  "580": "DO",

  // Add more mappings as needed
};

export const currencyLocaleMappings: { [key: string]: string } = {
  THB: "th-TH",
  USD: "en-US",
  DOP: "es-DO",
  // Add more mappings as needed
};

export const countryLocaleMappings: { [key: string]: string } = {
  "200": "th-TH",
  "010": "en-US",
  // Add more mappings as needed
};

export const countryMappings: { [key: string]: string } = {
  "200": "th",
  "010": "us",
  // Add more mappings as needed
};

export const currencyMappings: { [key: string]: string } = {
  "200": "usd",
  "010": "thb",
  // Add more mappings as needed
};

export const footerLinks: {
  [key: string]: { privacyPolicy: string; termsConditions: string };
} = {
  "010": {
    privacyPolicy: "https://www.amwayglobal.com/privacy-notice/united-states/",
    termsConditions:
      "https://www.amway.com/en_US/support-center/policies-and-terms#TermsofUseSubcategoryComponent",
  },
  "200": {
    privacyPolicy: "https://www.amwayglobal.com/privacy-notice/thailand/",
    termsConditions: "https://www.amway.co.th/terms-of-use",
  },
  "020": {
    privacyPolicy: "https://www.amwayglobal.com/privacy-notice/canada/",
    termsConditions:
      "https://www.amway.ca/en_CA/support-center/policies-and-terms#TermsofUseSubcategoryComponent",
  },
  "580": {
    privacyPolicy:
      "https://www.amwayglobal.com/privacy-notice/dominican-republic/",
    termsConditions:
      "https://www.amway.ca/en_CA/support-center/policies-and-terms#TermsofUseSubcategoryComponent",
  },
  // Add more mappings as needed
};

export const awardGroupCodeMapping: { [key: string]: string } = {
  all_accounts: "000-999",
  diamond_above_default: "380-999",
  founders_platinum_emerald: "342-375",
  sp_gp_platinum: "310-330",
};

export const aboColumnMapping: { [key: string]: { columnName: string, valueMapping: string | string[]; } } = {
  "tracking-award":
  {
    columnName: "Tracking_Award",
    valueMapping: "trackingAward.awardName",
  },
  "pggi-pq": {
    columnName: "PGI_PQs",
    valueMapping: "groupGrowthIncentive.kpiValue",
  },
  "fgiTracking":
  {
    columnName: "FGI_PQs",
    valueMapping: "fgiTracking.fgiTracking.multiplier",
  },
  "monthly-revenue":
  {
    columnName: "Monthly_Revenue",
    valueMapping: "monthlyRevenue.kpiValue",
  },
  "monthly-contributor":
  {
    columnName: "Monthly_Contributors",
    valueMapping: "monthlyContributor.kpiValue",
  },
  "annual-revenue":
  {
    columnName: "Annual_Revenue YTD",
    valueMapping: "annualRevenue.kpiValue",
  },
  "annual-contributor":
  {
    columnName: "Annual_Contributors",
    valueMapping: "annualContributor.kpiValue",
  },
  "gpv-last":
  {
    columnName: "Last_Month_GPV",
    valueMapping: "lastMonthGroupPV.kpiValue",
  },
  "gpv-current":
  {
    columnName: "This_Month_GPV",
    valueMapping: "currentMonthGroupPV.kpiValue",
  },
  "rpv-last":
  {
    columnName: "Last_Month_RPV",
    valueMapping: "lastMonthRubyPV.kpiValue",
  },
  "rpv-current":
  {
    columnName: "This_Month_RPV",
    valueMapping: "currentMonthRubyPV.kpiValue",
  },
  "ppv":
  {
    columnName: "This_Month_PPV",
    valueMapping: "currentMonthPersonalPV.kpiValue",
  },
  // "ql":
  // {
  //   columnName: "Q_Legs",
  //   valueMapping: "qualifiedLeg.kpiValue",
  // },
  // "qm":
  // {
  //   columnName: "Q_Months",
  //   valueMapping: "qualifiedMonth.kpiValue",
  // },
  "ttci-ttca":
  {
    columnName: "ttci_ttca",
    valueMapping: "trackingTwoTimeAward.trackingTwoTimeAward.awardCode",
  },
  "totalSPQualifiedMonthsLos":
  {
    columnName: "Total_Qs",
    valueMapping: "totalSPQualifiedMonthsLos.kpiValue"
  },
  "productivityPerContributor":
  {
    columnName: "productivity_contributor",
    valueMapping: "productivityPerContributor.kpiValue"
  }
}

type FilterDataType = {
  id: string;
  name: string;
}

export const NOTES_SORT_LIST: FilterDataType[] = [
  {
    id: "newest_first",
    name: "Newest First",
  },
  {
    id: "oldest_first",
    name: "Oldest First"
  }
];

export const noteTaglist: string[] = [
  "EAP",
  "Business Plan",
  "Event Support",
  "LGS Action",
  "BSM",
];

export const PAGE_SIZE = 20;

type FilterTileType = {
  kpiCode: string;
  title: string;
  label: string;
  category: string;
}
export const KPI_KEYS: { [key: string]: string } = {
  groupSize: "groupSize",
  contributors: "contributors",
  groupLeaderPPV: "groupLeaderPPV",
  volumeFlunctuation: "volumeFlunctuation",
  groupEomVolume: "groupEomVolume",
  tenure: "tenure"
};

export const ReportName: { [key: string]: string } = {
  "gar_tracking_report": "GAR Tracking / QC Analysis",
  "als_tracking_report": "ALS Points and Qualifiers Tracking",
};