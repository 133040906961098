import React, { useEffect, useState } from "react";
import styles from "./ReportLandingPage.module.scss";
import AccountListDropDown from "../../common/Report/AccountListDropdown/AccountListDropDown";
import ReportBuilder from "../ReportBuilder/ReportBuilder";
import Button from "../../common/Button/Button";
import { useTranslation } from "react-i18next";
import {
  useLazyGetReportBulderAboListQuery,
  useGetReportSummaryMutation,
  useLazyGetRecentSearchQuery,
  useUpdateRecentSearchMutation
} from "../../../redux/services/reportBuilderAPI";
import { isValidResponse } from "../../../utils/validateAPIResponse";
import { isEmpty } from "lodash";
import { useGetOptionsListQuery } from "../../../redux/services/optionMasterAPI";
import ErrorHandling from "../../ErrorHandling/ErrorHandling";

const ReportLandingPage: React.FC = () => {
  const [isGenerateReport, setIsGenerateReport] = useState(true);
  const [isReportTypeSelected, setIsReportTypeSelected] = useState<string>("");
  const [reportTypeAffCode, setReportTypeAffCode] = useState<string | number>("");
  const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);
  const [isReportPage, setIsReportPage] = useState(false);
  const [isAPIError, setIsAPIError] = useState(false);
  const [reportTypeData, setReportTypeData] = useState<ReportType[]>([]);
  const [reportSummary, setReportSummary] =
    useState<ReportData[]>([]);

  const { t } = useTranslation();
  const onBack = () => {
    setIsReportTypeSelected("");
    setIsGenerateReport(true);
    setIsReportPage(false);
    setReportTypeAffCode("");
  };

  const handleClickCreateNewReport = () => {
    setIsReportTypeSelected("");
    setIsGenerateReport(true);
    setIsReportPage(false);
    setSelectedItems([]);
    setReportSummary([]);
    setReportTypeAffCode("");
  };

  const [
    getReportBuilderABOList,
    {
      data: reportAboListData,
      isFetching: isLoadingGetReportsAboList,
      isError,
    },
  ] = useLazyGetReportBulderAboListQuery();

  const [
    getRecentSearchABOList,
    {
      data: recentSearchABOList,
      isFetching: isRecentSearchABOListLoading,
      isError: isRecentSearchQueryError,
    },
  ] = useLazyGetRecentSearchQuery();

  const [
    updateRecentSearchABOLists,
    {
      data: updateSearchABOList,
      isLoading: isUpdateSearchABOListLoading,
      isError: isUpdateSearchABOListError,
    },
  ] = useUpdateRecentSearchMutation();

  const handleAPIError = () => {
    setIsAPIError(true);
  };

  useEffect(() => {
    if (isError || isRecentSearchQueryError) setIsAPIError(true);
  }, [isError, isRecentSearchQueryError]);

  useEffect(() => {
    if (
      !isEmpty(reportAboListData) &&
      !isValidResponse(reportAboListData?.statusCode)
    ) {
      handleAPIError();
    }
  }, [reportAboListData]);

  useEffect(() => {
    if (
      !isEmpty(recentSearchABOList) &&
      !isValidResponse(recentSearchABOList?.statusCode)
    ) {
      handleAPIError();
    }
  }, [recentSearchABOList]);

  useEffect(() => {
    if (isReportTypeSelected) {
      getReportBuilderABOList({
        reportType: isReportTypeSelected,
        reportTypeAffCode: reportTypeAffCode,
        payload: { keyword: "" },
      });

      getRecentSearchABOList({
        reportTypeCode: 'abo',
      });
    }
  }, [isReportTypeSelected]);

  const { data, isLoading: isReportTypeLoading, error } = useGetOptionsListQuery({
    applicationCode: "amw",
    optionMasterName: "report-reporttype",
  }, {
    skip: isAPIError,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    error && setIsAPIError(true);
  }, [error])

  useEffect(() => {
    if (!isEmpty(data?.body?.options)) {
      if (isValidResponse(data?.statusCode)) {
        const resData: ReportType[] = data?.body?.options;
        setReportTypeData(resData || []);
      } else {
        handleAPIError();
      }
    }
  }, [data]);

  const onABOSearch = (searchTerm: string) => {
    getReportBuilderABOList({
      reportType: isReportTypeSelected,
      reportTypeAffCode: reportTypeAffCode,
      payload: { keyword: searchTerm || "" },
    });
  };

  const [
    getReportSummary,
    { isLoading: isLoadingReport, error: isReportError },
  ] = useGetReportSummaryMutation();

  useEffect(() => {
    isReportError && handleAPIError();
  }, [isReportError])

  const generateReportHandler = async () => {
    if (!selectedItems.length || !isReportTypeSelected) {
      return;
    }
    setIsGenerateReport(false);
    setIsReportPage(true);
    try {
      const payload: ReportRequest = {
        reportTypeCode: isReportTypeSelected,
        abos: selectedItems?.map(({ affiliateCode, aboNumber }) => ({
          affiliateCode,
          aboNumber,
        })),
      };

      const reportResponse: ReportSummaryResponse = await getReportSummary(payload).unwrap();
      setReportSummary(reportResponse.body);
    } catch (err) {
      console.error("Error generating report:", err);
      handleAPIError();
    }
    try {
      updateRecentSearchABOLists({
        reportTypeCode: 'abo',
        payload: selectedItems?.map(({ affiliateCode, aboNumber }) => ({
          affiliateCode,
          aboNumber,
        }))
      });
    } catch (err) {
      console.error("Error updating recent search:", err);
    }
  };

  return (
    <div className={`report_wrapper ${styles.report_wrapper}`}>
      {isReportPage ? (
        <ReportBuilder
          onClickCreateNewReportHandler={handleClickCreateNewReport}
          reportSummary={reportSummary}
          isReportTypeSelected={isReportTypeSelected}
          selectedABOs={selectedItems}
          isLoadingReport={isLoadingReport}
        />
      ) : (
        <>
          {!isAPIError && (<div className={styles.report_header_wrapper}>
            <div className={styles.heading_wrapper}>
              <div className={`report_heading ${styles.report_heading}`}>
                {t("report_builder")}
              </div>
              <div className={`report_subheading ${styles.report_subheading}`}>
                {isReportTypeSelected
                  ? t("select_account")
                  : t("select_report")}
              </div>
            </div>
            {isReportTypeSelected && (
              <div className={`nav_btn_wrapper ${styles.nav_btn_wrapper}`}>
                <Button
                  id="backButton"
                  onClick={onBack}
                  label={t("report_back_button")}
                  style="unfilled"
                />
                <Button
                  id="generateReportButton"
                  onClick={generateReportHandler}
                  isDisabled={selectedItems.length === 0}
                  label={t("generate_report")}
                  style="filled"
                />
              </div>
            )}
          </div>
          )}
          {isReportTypeLoading ? (
            <div className={styles.card_wrapper}>
              {Array.from({ length: reportTypeData.length || 2 }).map(
                (_, index) => (
                  <div className={`${styles.card_loader} animate`} key={index}>
                    <div className={`${styles.title} animate`}></div>
                    <div className={`${styles.content_loader}`}>
                      <div className={`${styles.first_line} animate`}></div>
                      <div className={`${styles.first_line} animate`}></div>
                      <div className={`${styles.first_line} animate`}></div>
                    </div>
                  </div>
                )
              )}
            </div>
          ) : isAPIError ? <ErrorHandling error={"genericError"} /> :
            !isReportTypeSelected && !isReportTypeLoading ? (
              <div className={`card_container ${styles.card_container}`}>
                {reportTypeData.map((report, index) => (
                  <div
                    key={index}
                    className={`card ${styles.card}`}
                    onClick={() => { setIsReportTypeSelected(report.code); setReportTypeAffCode(report.affiliateCode) }}
                  >
                    <div className={`card_heading ${styles.card_heading}`}>
                      {report.value}
                    </div>
                    <div className={`card_content ${styles.card_content}`}>
                      Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor
                      sit amet consectetur. Congue quam sed ut eu sed. Duis tempor
                      sagittis posuere et ac lorem.
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.report_builder_section}>
                <AccountListDropDown
                  onABOSelection={setSelectedItems}
                  recentSearchListData={recentSearchABOList?.body || []}
                  reportAboListData={reportAboListData?.body || []}
                  onABOSearch={onABOSearch}
                  isLoadingGetReportsAboList={isLoadingGetReportsAboList}
                  isRecentSearchABOListLoading={isRecentSearchABOListLoading}
                />
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default ReportLandingPage;
