import React, { useEffect, useState } from "react";
import map from "lodash/map";
import KPIsPill from "../BusinessPerformance/KPIsPillSection/KPIsPill";
import AGCDetailsTile from "../../../common/AGCDetailsTile/AGCTile";
import Summary from "../BusinessPerformance/Summary/Summary";
import { isEmpty } from "lodash";
import styles from "./KPIsAndTilesSection.module.scss";
import { useGetBusinessPerformanceDetailsQuery } from "../../../../redux/services/businessPerformaceAPI";
import { useAppSelector } from "../../../../redux/hooks";
import ErrorInPageScreen from "../../../common/ErrorInPageScreen/ErrorInPageScreen";
import { isValidResponse } from "../../../../utils/validateAPIResponse";
import ABOBackgroundLoader from "../../../common/Loaders/ABOBackgroundLoader/ABOBackgroundLoader";
import { useGetAGCAIDetailsMutation } from "../../../../redux/services/agcAIAPI";
import { useProfileParams } from "../../../../utils/common";

const KPIsAndTilesSection: React.FC<
  KPIsAndTilesSectionProps & CategoriesProps
> = ({ tileDataList, kpiList, SeperatorIcon, category }) => {
  const userDetails = useAppSelector((state: any) => state.userDetails.user);
  const { affiliateCode, aboNumber } = useProfileParams();
  const [isSummarySelected, setIsSummarySelected] = useState<boolean>(true);
  const [selectedKPIs, setSelectedKPIs] = useState<string[]>([]);
  const [AGCDetailsList, setAGCDetailsList] = useState<any>([]);
  const [isAPIError, setIsAPIError] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState<number | null>(90);
  const [agcAIDetailsList, setAGCAIDetailsList] = useState(null);

  const selectDeselctFunctionality = (clickedKpis: string) => {
    const isAvailable = selectedKPIs.includes(clickedKpis);
    if (isAvailable) {
      const updatedArray = selectedKPIs.filter((name) => name !== clickedKpis);
      setSelectedKPIs(updatedArray);
      if (isEmpty(updatedArray)) {
        setIsSummarySelected(true);
      }
    } else {
      setSelectedKPIs([...selectedKPIs, clickedKpis]);
      setIsSummarySelected(false);
    }
  };

  const selectPill = (name: string) => {
    selectDeselctFunctionality(name);
  };

  const selectSummaryPill = () => {
    setIsSummarySelected(true);
    setSelectedKPIs([]);
  };

  const handleTileClose = (updatedData: any[]) => {
    const updatedKpiNames = updatedData.map((kpi) =>
      typeof kpi === "string" ? kpi : kpi.kpiName
    );
    const newSelectedKPIs = selectedKPIs.filter(
      (kpi) => !updatedKpiNames.includes(kpi)
    );
    setSelectedKPIs(newSelectedKPIs);
    if (newSelectedKPIs?.length === 0) {
      setIsSummarySelected(true);
    }
  };

  const generateKpiCode = (code: string, period: number | null): string => {
    switch (period) {
      case 30:
        return "activationRate30";
      case 60:
        return "activationRate60";
      case 90:
        return "activationRate90";
    }
    return code;
  };
  const filteredTiles = tileDataList?.filter((tileData) => {
    return selectedKPIs.includes(tileData.code);
  });
  const handleSelectPeriod = (period: number) => {
    setSelectedPeriod(period);
  };

  const kpiCodes = map(filteredTiles, (tile) => {
    return {
      code:
        tile?.code === "activationRate:90|30|60"
          ? generateKpiCode(tile?.code, selectedPeriod)
          : tile?.code,
    };
  });

  const {
    isFetching,
    data: AGCDetailsResponse,
    isError: isErrorInGetAGCDetails,
  } = useGetBusinessPerformanceDetailsQuery(
    {
      affiliateCode,
      aboNumber,
      payload: { kpis: kpiCodes },
    },
    {
      skip:
        Number(affiliateCode) === 0 ||
        aboNumber === 0 ||
        isEmpty(kpiCodes),
      refetchOnMountOrArgChange: true,
    }
  );

  const handleAPIError = () => {
    setIsAPIError(true);
    setAGCDetailsList([]);
  };

  useEffect(() => {
    isErrorInGetAGCDetails && handleAPIError();
  }, [isErrorInGetAGCDetails]);

  useEffect(() => {
    if (!isEmpty(AGCDetailsResponse)) {
      if (isValidResponse(AGCDetailsResponse?.statusCode)) {
        setIsAPIError(false);
        setAGCDetailsList(AGCDetailsResponse?.body?.kpis);
      } else {
        handleAPIError();
      }
    }
  }, [AGCDetailsResponse, isFetching]);

  const [
    getAGCAIDetails,
    { isLoading: isLoadingAGCAIDetails, isError: isErrorInAGCDetails },
  ] = useGetAGCAIDetailsMutation();

  useEffect(() => {
    if (!isEmpty(kpiCodes)) {
      getAGCAIDetails({
        affiliateCode,
        aboNumber,
        payload: { kpis: kpiCodes },
      }).then((resp: any) => {
        setAGCAIDetailsList(resp?.data?.body ?? {});
      });
    }
  }, [selectedKPIs, selectedPeriod]);

  const getKPICode = (tempKpiCode: string) => {
    return tempKpiCode === "activationRate:90|30|60"
          ? generateKpiCode(tempKpiCode, selectedPeriod)
          : tempKpiCode
  }

  return (
    <div className={styles.kpi_and_tile_container}>
      <div className={styles.outer_pill_container}>
        <KPIsPill
          code="Summary"
          label="Summary"
          onPillClick={selectSummaryPill}
          isSummary={true}
          isSummarySelected={isSummarySelected}
        />
        <div className={styles.pill_container}>
          <img
            src={SeperatorIcon}
            alt="separator"
            className={styles.separator_icon}
          />
          {kpiList.map((kpi,index) => (
            <KPIsPill
              key={`${index}${kpi?.code}`}
              code={kpi.code}
              label={kpi.shortName}
              isSummary={false}
              isSelected={selectedKPIs.includes(kpi.code)}
              onPillClick={selectPill}
            />
          ))}
        </div>
      </div>
      {isSummarySelected ? (
        <Summary
          selectPill={selectPill}
          category={category}
          kpiList={kpiList}
        />
      ) : isFetching ? (
        <ABOBackgroundLoader num={5} heading={""} />
      ) : isAPIError ? (
        <ErrorInPageScreen
          handleClickTryAgain={() => {
            setIsAPIError(false);
          }}
        />
      ) : (
        <div className={styles.tiles_container}>
          {filteredTiles.map((tile, index) => (
            <AGCDetailsTile
              key={tile.code}
              kpiName={tile.code}
              title={tile.longName}
              label={tile.shortName}
              description={tile.description}
              updatedAGC={handleTileClose}
              agcDetailsData={AGCDetailsList?.[index]}
              onSelectPeriod={handleSelectPeriod}
              agcAIContent={agcAIDetailsList?.[getKPICode(tile.code)]}
              isLoadingAGCAIDetails={isLoadingAGCAIDetails}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default KPIsAndTilesSection;
