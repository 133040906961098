import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import styles from "./ABOBackground.module.scss";
import { useAppSelector } from "../../../../redux/hooks";
import {
  useGetAboBackgroundDetailsQuery,
  useSetAboBackgroundDetailsMutation,
} from "../../../../redux/services/aboBackgroundDetailsAPI";
import { formatDate } from "../../../../utils/dateFormatUtil";
import Button from "../../../common/Button/Button";
import FormatterBar from "../../../common/FormatterBar/FormatterBar";
import HTMLtoText from "../../../common/HTMLToText/HTMLToText";
import ABOBackgroundLoader from "../../../common/Loaders/ABOBackgroundLoader/ABOBackgroundLoader";
import ErrorInPageScreen from "../../../common/ErrorInPageScreen/ErrorInPageScreen";
import { isValidResponse } from "../../../../utils/validateAPIResponse";
import { useProfileParams } from "../../../../utils/common";

function ABOBackground() {
  const { t } = useTranslation();
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const userDetails = useAppSelector((state: any) => state.userDetails.user);
  const { affiliateCode, aboNumber } = useProfileParams();
  const [ABOBackgroundData, setABOBackgroundData] =
    useState<ABOBackgroundDataType | null>(null);
  const [updatedABOBackgroundData, setUpdatedABOBackgroundData] =
    useState<ABOBackgroundDataType | null>(null);
  const [isEditable, setIsEditable] = useState(false);
  const [isAPIError, setIsAPIError] = useState(false);

  const handleDataChange = (editorData: any, section: string) => {
    setUpdatedABOBackgroundData((updatedABOBackgroundData) => ({
      ...updatedABOBackgroundData,
      [section]: editorData,
    }));
  };

  const { data, isFetching, isError } = useGetAboBackgroundDetailsQuery(
    {
      affiliateCode, 
      aboNumber,
    },
    {
      skip:
        Number(affiliateCode) === 0 ||
        aboNumber === 0 ||
        isAPIError,
      refetchOnMountOrArgChange: true,
    }
  );

  const handleAPIError = () => {
    setIsAPIError(true);
    setABOBackgroundData(null);
    setUpdatedABOBackgroundData(null);
  };

  useEffect(() => {
    isError && handleAPIError();
  }, [isError]);

  useEffect(() => {
    if (!isEmpty(data)) {
      if (isValidResponse(data?.statusCode)) {
        setABOBackgroundData(data?.body ?? {});
        setUpdatedABOBackgroundData(data?.body ?? {});
      } else {
        handleAPIError();
      }
    }
  }, [data, isFetching]);

  const [
    updateABOBackgroundDetails,
    { isLoading: isLoadingABOBackgroundDetails, isError: isErrorInSave },
  ] = useSetAboBackgroundDetailsMutation();

  useEffect(() => {
    isErrorInSave && handleAPIError();
  }, [isErrorInSave]);

  const handleClickEdit = () => {
    setIsEditable(true);
  };

  const handleClickCancel = () => {
    setIsEditable(false);
    setUpdatedABOBackgroundData(ABOBackgroundData);
  };

  const handleClickSave = async () => {
    const body = {
      occupation: updatedABOBackgroundData?.occupation ?? "",
      education: updatedABOBackgroundData?.education ?? "",
      family: updatedABOBackgroundData?.family ?? "",
      hobby: updatedABOBackgroundData?.hobby ?? "",
      other: updatedABOBackgroundData?.other ?? "",
    };

    const response: any = await updateABOBackgroundDetails({
      affiliateCode,
      aboNumber,
      payload: body,
    });

    if (isValidResponse(response?.data?.statusCode)) {
      setIsEditable(false);
      setABOBackgroundData(response?.data?.body);
      setUpdatedABOBackgroundData(response?.data?.body);
    } else {
      handleAPIError();
    }
  };

  return (
    <div
      className={`abo_bg_container ${styles.abo_background_container} ${isAPIError ? styles.full_width : ""}`}
    >
      {isFetching || isLoadingABOBackgroundDetails ? (
        <ABOBackgroundLoader num={5} heading={t("IBO_background")} />
      ) : isAPIError ? (
        <>
          <div className={styles.heading_area}>
            <span
              className={styles.heading}
              data-testid="abo_bg_heading_testID"
            >
              {t("IBO_background")}
            </span>
          </div>
          <ErrorInPageScreen
            handleClickTryAgain={() => {
              setIsAPIError(false);
            }}
          />
        </>
      ) : (
        <>
          <div className={styles.heading_area}>
            <span
              className={styles.heading}
              data-testid="abo_bg_heading_testID"
            >
              {t("IBO_background")}
            </span>
            <span className={styles.right_section}>
              {!isEditable && (
                <span
                  className={styles.edit_section}
                  onClick={handleClickEdit}
                  data-testid="edit_testID"
                >
                  <span
                    className={
                      currentTheme === "light"
                        ? styles.edit_icon_light
                        : styles.edit_icon_dark
                    }
                  ></span>
                  <span>{t("edit")}</span>
                </span>
              )}
            </span>
          </div>
          <div className={`grey_text ${styles.sub_heading}`}>
            {t("abo_background_sub_title")}
          </div>
          <div className={`grey_text ${styles.last_modified}`}>
            {t("last_modified")}{" "}
            {formatDate(ABOBackgroundData?.audit?.updatedDate)}
          </div>
          <div className={styles.details_wrapper}>
            <div className={styles.item_wrapper}>
              {isEditable ? (
                <FormatterBar
                  id="occupation"
                  testId="occupation_testID"
                  label="occupation"
                  editorData={ABOBackgroundData?.occupation || ""}
                  handleDataChange={handleDataChange}
                  section={"occupation"}
                  isContentEditable={false}
                  maxCharacter={2000}
                />
              ) : (
                <>
                  <div
                    className={styles.item_name}
                    data-testid="abo_bg_item_testID"
                  >
                    {t("occupation")}
                  </div>
                  <div className={`grey_text ${styles.item_value}`}>
                    <HTMLtoText
                      htmlContent={ABOBackgroundData?.occupation || ""}
                      alternateMessage={t("no_info_available")}
                    />
                  </div>
                </>
              )}
            </div>
            <div className={styles.item_wrapper}>
              {isEditable ? (
                <FormatterBar
                  id="education"
                  testId="education_testID"
                  label="education"
                  editorData={ABOBackgroundData?.education || ""}
                  handleDataChange={handleDataChange}
                  section={"education"}
                  isContentEditable={false}
                  maxCharacter={2000}
                />
              ) : (
                <>
                  <div
                    className={styles.item_name}
                    data-testid="abo_bg_item_testID"
                  >
                    {t("education")}
                  </div>
                  <div className={`grey_text ${styles.item_value}`}>
                    <HTMLtoText
                      htmlContent={ABOBackgroundData?.education || ""}
                      alternateMessage={t("no_info_available")}
                    />
                  </div>
                </>
              )}
            </div>
            <div className={styles.item_wrapper}>
              {isEditable ? (
                <FormatterBar
                  id="family"
                  testId="family_bg_testID"
                  label="family_background"
                  editorData={ABOBackgroundData?.family || ""}
                  handleDataChange={handleDataChange}
                  section={"family"}
                  isContentEditable={false}
                  maxCharacter={2000}
                />
              ) : (
                <>
                  <div
                    className={styles.item_name}
                    data-testid="abo_bg_item_testID"
                  >
                    {t("family_background")}
                  </div>
                  <div className={`grey_text ${styles.item_value}`}>
                    <HTMLtoText
                      htmlContent={ABOBackgroundData?.family || ""}
                      alternateMessage={t("no_info_available")}
                    />
                  </div>
                </>
              )}
            </div>
            <div className={styles.item_wrapper}>
              {isEditable ? (
                <FormatterBar
                  id="hobby"
                  testId="hobby_testID"
                  label="hobbies"
                  editorData={ABOBackgroundData?.hobby || ""}
                  handleDataChange={handleDataChange}
                  section={"hobby"}
                  isContentEditable={false}
                  maxCharacter={2000}
                />
              ) : (
                <>
                  <div
                    className={styles.item_name}
                    data-testid="abo_bg_item_testID"
                  >
                    {t("hobbies")}
                  </div>
                  <div className={`grey_text ${styles.item_value}`}>
                    <HTMLtoText
                      htmlContent={ABOBackgroundData?.hobby || ""}
                      alternateMessage={t("no_info_available")}
                    />
                  </div>
                </>
              )}
            </div>
            <div className={styles.item_wrapper}>
              {isEditable ? (
                <FormatterBar
                  id="other"
                  testId="other_testID"
                  label="other"
                  editorData={ABOBackgroundData?.other || ""}
                  handleDataChange={handleDataChange}
                  section={"other"}
                  isContentEditable={false}
                  maxCharacter={2000}
                />
              ) : (
                <>
                  <div
                    className={styles.item_name}
                    data-testid="abo_bg_item_testID"
                  >
                    {t("other")}
                  </div>
                  <div className={`grey_text ${styles.item_value}`}>
                    <HTMLtoText
                      htmlContent={ABOBackgroundData?.other || ""}
                      alternateMessage={t("no_info_available")}
                    />
                  </div>
                </>
              )}
            </div>
          </div>

          {isEditable && (
            <div className={styles.btn_wrapper}>
              <Button
                id="cancelButton"
                onClick={handleClickCancel}
                label={t("btnCancel")}
                style="unfilled"
              />
              <Button
                id="saveButton"
                onClick={handleClickSave}
                label={t("btnSave")}
                style="filled"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ABOBackground;
