import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "./axiosBaseQuery/axiosBaseQuery";
import config from "../../config/config";

export const reportBuilderABOListAPI = createApi({
  reducerPath: "reportBuilderABOListAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: config?.appUrl,
  }),
  keepUnusedDataFor: 900,
  tagTypes: ["rbabolist", "rbrecentsearchlist"],
  endpoints: (builder) => ({
    getReportBulderAboList: builder.query<
      any,
      { reportType: string; reportTypeAffCode: string | number; payload: any }
    >({
      query: ({ reportType, reportTypeAffCode, payload }) => ({
        url: `${reportType === "gar_tracking_report" ? `/am/report/abos/affiliate/${reportTypeAffCode}/diamond` : `/am/report/abos/affiliate/${reportTypeAffCode}`}`,
        method: "POST",
        data: payload,
      }),
      providesTags: ["rbabolist"],
    }),
    getReportSummary: builder.mutation<ReportSummaryResponse, ReportRequest>({
      query: (payload) => ({
        url: "/am/report/generate",
        method: "POST",
        data: payload,
      }),
    }),
    getRecentSearch: builder.query<ReportRecentSearcResponse, ReportRecentSearch>({
      query: ({ reportTypeCode }) => ({
        url: `/am/search/recent/type/${reportTypeCode}`,
        method: "GET",
      }),
      providesTags: ["rbrecentsearchlist"],
    }),
    updateRecentSearch: builder.mutation<ReportRecentSearcResponse, ReportRecentUpsertSearchRequest>({
      query: ({ reportTypeCode, payload }) => ({
        url: `/am/search/recent/type/${reportTypeCode}`,
        method: "POST",
        data: payload
      }),
      invalidatesTags: ["rbrecentsearchlist"],
    })
  }),
});

export const {
  useLazyGetReportBulderAboListQuery,
  useGetReportSummaryMutation,
  useLazyGetRecentSearchQuery,
  useUpdateRecentSearchMutation
} = reportBuilderABOListAPI;
