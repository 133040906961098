import React, { useState, useEffect } from "react";
import styles from "./Qualifications.module.scss";
import { useTranslation } from "react-i18next";
import KPIsAndTilesSection from "../KPIsAndTilesSection/KPIsAndTilesSection";
import SeperatorLineDark from "../../../../assets/images/DarkTheme/seperator_line.svg";
import { useGetKpiSettingsQuery } from "../../../../redux/services/kpiSettingsByTypeAPI";
import KPIsListDataMassager from "../../../CustomHooks/useGetMassagedKPIsList";
import { useAppSelector } from "../../../../redux/hooks";
import { useProfileParams } from "../../../../utils/common";

const Qualifications: React.FC<CategoriesProps> = ({ category }) => {
  const { t } = useTranslation();
  const [qualificationKPIsList, setQualificationKPIsList] = useState<any>([]);
  const massagedKPIslist = KPIsListDataMassager(qualificationKPIsList ?? []);
  const userDetails = useAppSelector((state: any) => state.userDetails.user);
  const { affiliateCode, aboNumber } = useProfileParams();

  const { data: qualificationKpisResponse } = useGetKpiSettingsQuery({
    type: "business-performance-qualification",
    affiliateCode
  },
    { refetchOnMountOrArgChange: true });

  useEffect(() => {
    const tempKPIslist = qualificationKpisResponse?.body;
    setQualificationKPIsList(tempKPIslist);
  }, [qualificationKpisResponse]);

  return (
    <div className={styles.qualifications_wrapper}>
      <div className={styles.heading_area}>
        <div className={styles.heading}>{t("qualifications")}</div>
        <div className={`section_sub_heading ${styles.sub_heading}`}>
          {t("qualification_summary")}
        </div>
      </div>
      <KPIsAndTilesSection
        tileDataList={massagedKPIslist}
        kpiList={massagedKPIslist}
        SeperatorIcon={SeperatorLineDark}
        category={category}
      />
    </div>
  );
};

export default Qualifications;
