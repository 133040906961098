import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import styles from "./APNotes.module.scss";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import Button from "../../../../common/Button/Button";
import { useAppSelector } from "../../../../../redux/hooks";
import sortIconLight from "../../../../../assets/images/LightTheme/up_down.svg";
import sortIconDark from "../../../../../assets/images/DarkTheme/up_down_dark.svg";
import FormatterBar from "../../../../common/FormatterBar/FormaterBarWithLinkmodel/FormatterBar";
import {
  useGetAPNotesListQuery,
  useCreateAPNoteMutation,
  useEditAPNoteMutation,
  useDeleteAPNoteMutation,
  useGetUsersListByNativeIDsQuery,
} from "../../../../../redux/services/apNotesAPI";
import { RootState } from "../../../../../redux/store";
import {
  NOTES_SORT_LIST,
  noteTaglist,
} from "../../../../../constants/dataConstants";
import ABOBackgroundLoader from "../../../../common/Loaders/ABOBackgroundLoader/ABOBackgroundLoader";
import ErrorInPageScreen from "../../../../common/ErrorInPageScreen/ErrorInPageScreen";
import { isValidResponse } from "../../../../../utils/validateAPIResponse";
import { getUserNativeIDsArray } from "../../../../../utils/progressNotesUtil";
import { UserDetails } from "../../../../../types/types";
import NoteItem from "../../../../common/Notes/Notes";
import APNotesSearchBar from "./APNotesSearch/APNotesSearchBar";
import { filter, includes } from "lodash";
import TagDropdown from "../../../../common/TagDropodown/TagDropdown";
import ToastMessage from "../../../../common/ToastMessage/ToastMessage";
import Modal from "../../../../common/Modal/Modal";
import { disableEnableScroll } from "../../../../../utils/disableEnableScroll";
import DeleteConfirmationPopUp from "../../../../common/DeleteConfirmationPopUp/DeleteConfirmationPopUp";
import Icon from "../../../../common/Icon/Icon";
import useClickOutside from "../../../../CustomHooks/useClickOutside";

interface APNoteProps { }

interface APNotesType {
  apNoteId: number;
  note: string;
  title: string;
  performanceYear: number;
  tag: string[];
  audit: {
    createdDate: string;
    createdBy: string;
    updatedDate: string;
    updatedBy: string;
  };
}
type FilterDataType = {
  id: string;
  name: string;
};

const APNotes: React.FC<APNoteProps> = () => {
  const { t } = useTranslation();
  const [APNotesList, setAPNotesList] = useState<APNotesType[]>([]);
  const [APNotesRaw, setAPNotesRaw] = useState<APNotesType[]>([]);
  const [userNativeIDs, setUserNativeIDs] = useState<string[]>([]);
  const [updatedNativeIDs, setUpdatedNativeIDs] = useState<string[]>([]);
  const [usersListByNativeId, setUsersListByNativeId] = useState<
    UserDetails[] | null
  >([]);
  const [textareaValue, setTextareaValue] = useState<string>("");
  const [updatedTags, setUpdatedTags] = useState<string[]>([]);
  const [isEditable, setIsEditable] = useState(false);
  const [isNoteEditable, setNoteEditable] = useState(false);
  const [saveEnable, setSaveEnable] = useState(false);
  const [editNoteId, setEditNoteId] = useState<number | null>(null);
  const currentTheme = useAppSelector(
    (state: RootState) => state.theme.currentTheme
  );
  const userDetails = useAppSelector(
    (state: RootState) => state.userDetails.user
  );
  const [userAffiliateCode] = useState(userDetails?.userAffiliateCode || 0);
  const [isAPIError, setIsAPIError] = useState<boolean>(false);
  const sortIcon = currentTheme === "light" ? sortIconLight : sortIconDark;
  const [selectedSort, setSelectedSort] = useState<FilterDataType>(
    NOTES_SORT_LIST?.[0]
  );
  const [activeFilterTag, setActiveFilterTag] = useState<string | null>(null);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState<number | null>(null);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const { selectedAP } = useAppSelector((state) => state.apDetails);
  const apCode = selectedAP?.code;
  const timeOutPeriod = 3000;
  const titleAPInfoNotes = "AP_info.notes";
  const addInternalNote = "AP_info.add_internal_notes";

  const customButtonStyles: React.CSSProperties = {
    textAlign: "center",
    fontFamily: "gt-walsheim-regular",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "18px",
  };

  const focusHandler = () => {
    setSaveEnable(false);
    setIsEditable(true);
    setNoteEditable(false);
  };
  const handleClickCancel = () => {
    setIsEditable(false);
    setNoteEditable(false);
  };
  const handleNoteCancel = () => {
    setEditNoteId(null);
    setNoteEditable(false);
    setIsEditable(false);
    setTextareaValue("");
    setUpdatedTags([]);
    setSaveEnable(false);
  };
  const handleDeleteModalToggle = () => {
    setModalOpen(false);
  };

  const handleNoteDelete = (noteId: number | null) => {
    setNoteToDelete(noteId);
    setModalOpen(true);
  };
  const confirmDeleteNote = () => {
    setModalOpen(false);

    if (noteToDelete !== null) {
      deleteProgressNote({
        id: noteToDelete,
      })
        .then(() => {
          setAPNotesList(
            APNotesList.filter((note) => note.apNoteId !== noteToDelete)
          );
          setToastMessage("Note deleted successfully!");
        })
        .catch(() => {
          setToastMessage("Failed to delete the note.");
        })
        .finally(() => {
          setShowToast(true);
          setTimeout(() => {
            setShowToast(false);
          }, timeOutPeriod);
        });
    }
  };

  const handleClickSortIcon = () => {
    setIsOpenPopup(!isOpenPopup);
  };

  const handleChangeSort = (sortItem: FilterDataType) => {
    if (sortItem?.id !== selectedSort?.id) {
      const sortedNotes = [...APNotesList].reverse();

      setAPNotesList(sortedNotes);
    }
    setSelectedSort(sortItem);
  };

  useEffect(() => {
    disableEnableScroll(modalOpen);
  }, [modalOpen]);
  //API call to get list of all notes
  const {
    isFetching: isNotesLoading,
    isError: isErrorInFetchNotes,
    data: notesData,
  } = useGetAPNotesListQuery(
    {
      apCode: apCode,
      year: "0",
    },
    { skip: isAPIError }
  );
  // Get the list of user details
  const { data: usersList } = useGetUsersListByNativeIDsQuery(
    {
      nativeIDs: userNativeIDs,
    },
    {
      skip: isEmpty(userNativeIDs) || userNativeIDs === updatedNativeIDs,
    }
  );
  useEffect(() => {
    setUsersListByNativeId(usersList?.body);
    if (!isEmpty(usersList)) {
      setUpdatedNativeIDs(userNativeIDs);
    }
  }, [usersList]);
  const sortDataByOrder = (sortByNewest: boolean) => {
    if (sortByNewest) {
      return [...notesData.body]
        .filter((note) => note.audit && note.audit.updatedDate)
        .sort(
          (a, b) =>
            new Date(b.audit.updatedDate).getTime() -
            new Date(a.audit.updatedDate).getTime()
        );
    } else {
      return [...notesData.body]
        .filter((note) => note.audit && note.audit.updatedDate)
        .sort(
          (a, b) =>
            new Date(a.audit.updatedDate).getTime() -
            new Date(b.audit.updatedDate).getTime()
        );
    }
  };

  const handleAPIError = () => {
    setIsAPIError(true);
    setAPNotesList([]);
  };

  useEffect(() => {
    if (notesData && Array.isArray(notesData.body)) {
      if (isValidResponse(notesData?.statusCode)) {
        const sortedNotes = sortDataByOrder(
          selectedSort?.id === "newest_first"
        );
        setAPNotesList(sortedNotes);
        setAPNotesRaw(sortedNotes);
        setUserNativeIDs(getUserNativeIDsArray(sortedNotes));
      } else {
        handleAPIError();
      }
    }
  }, [notesData, isNotesLoading]);

  const [
    deleteProgressNote,
    { isLoading: isLoadingDelete, isError: isErrorInDelete },
  ] = useDeleteAPNoteMutation();

  const [
    createNotes,
    { isLoading: isNotesCreating, isError: isErrorInCreateNote },
  ] = useCreateAPNoteMutation();

  const [editNotes, { isLoading: isNotesEditing, isError: isErrorInEditNote }] =
    useEditAPNoteMutation();

  useEffect(() => {
    if (
      isErrorInFetchNotes ||
      isErrorInCreateNote ||
      isErrorInEditNote ||
      isErrorInDelete
    ) {
      handleAPIError();
    }
  }, [
    isErrorInFetchNotes,
    isErrorInCreateNote,
    isErrorInEditNote,
    isErrorInDelete,
  ]);
  const handleClickSave = async (
    id?: number | null,
    val?: string,
    tagVal?: string[]
  ): Promise<void> => {
    const noteId = id ?? undefined;
    const payload = {
      performanceYear: 0,
      title: "",
      note: val || textareaValue,
      tag: tagVal || updatedTags,
    };
    try {
      const noteIdString =
        noteId !== undefined ? noteId.toString() : editNoteId || "";
      // Editing an existing note
      if (noteId || editNoteId) {
        const editNotesResponse: any = await editNotes({
          apCode: apCode,
          payload: { ...payload, apNoteId: noteIdString },
        });

        setEditNoteId(null);
        const editedNewValue = editNotesResponse?.data?.body?.note;
        if (isValidResponse(editNotesResponse?.data?.statusCode)) {
          setToastMessage("Note updated successfully!");
          setUpdatedTags(updatedTags);
          setTextareaValue(editedNewValue);
        } else {
          handleAPIError();
          setToastMessage("Failed to update the note.");
        }
      } else {
        // Creating a new note
        const response: any = await createNotes({
          apCode: apCode,
          payload: payload,
        });

        if (isValidResponse(response?.data?.statusCode)) {
          setToastMessage("Note created successfully!");
        } else {
          handleAPIError();
          setToastMessage("Failed to create the note.");
        }
      }
    } catch (error) {
      handleAPIError();
      setToastMessage("An error occurred while saving the note.");
    } finally {
      setIsEditable(false);
      setNoteEditable(false);
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, timeOutPeriod);
    }
    // Reset filter of tags when click save called.
    setAPNotesList(notesData.body);
    setActiveFilterTag(null);
    setUpdatedTags([]);
    setSaveEnable(false);
  };

  const handleNoteEdit = (note: NoteType) => {
    setSaveEnable(false);
    setNoteEditable(true);
    setIsEditable(false);
    setTextareaValue(note.note);
    setEditNoteId(note.apNoteId);
    setUpdatedTags(note.tag);
  };

  const textAreaChangeHandler = (editorData: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(editorData, "text/html");
    const rowNote = doc.body.textContent || "";
    setSaveEnable(!!(rowNote && rowNote?.length > 0));
    setTextareaValue(editorData === "<p><br></p>" ? "" : editorData);
  };

  const searchAPNotes = async (searchTerm: string) => {
    const filteredNotesData = getSearchFilteredNotesList(
      APNotesRaw,
      searchTerm
    );
    if (!searchTerm || searchTerm === "") {
      setAPNotesList(APNotesRaw);
    } else {
      setAPNotesList(filteredNotesData);
    }
  };

  const handleChangeTags = (changedTagsArray: string[]) => {
    const updatedAPNotes = APNotesList.map((noteValue) =>
      noteValue.apNoteId === editNoteId
        ? { ...noteValue, tags: changedTagsArray }
        : noteValue
    );
    setAPNotesList(updatedAPNotes);
    setUpdatedTags(changedTagsArray);
  };

  const getSearchFilteredNotesList = (
    notesList: APNotesType[],
    searchString = ""
  ) => {
    if (!searchString || searchString === "") {
      return notesList;
    }

    return filter(notesList, (notes: APNotesType) => {
      const { performanceYear, title, note } = notes;

      if (
        includes(
          performanceYear?.toString(),
          searchString?.trim()?.toLowerCase()
        ) ||
        includes(title?.toLowerCase(), searchString?.trim()?.toLowerCase()) ||
        includes(note?.toLowerCase(), searchString?.trim()?.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    });
  };

  const filterTagHandler = (filterTag: string) => {
    if (!notesData || !Array.isArray(notesData.body)) {
      return;
    }
    if (activeFilterTag === filterTag) {
      setAPNotesList(notesData.body);
      setActiveFilterTag(null);
    } else {
      const filteredNotes = notesData.body.filter((note: APNotesType) =>
        note.tag.includes(filterTag)
      );
      setAPNotesList(filteredNotes);
      setActiveFilterTag(filterTag);
    }
  };
  const dropdownRef = useRef<HTMLDivElement>(null);
  useClickOutside(dropdownRef, () => setIsOpenPopup(false));

  const getIsLoadingNotes = () => {
    return (
      isNotesCreating || isNotesLoading || isNotesEditing || isLoadingDelete
    );
  };

  const getConditionalFilterTagClass = (filterTag: string) => {
    return activeFilterTag === filterTag
      ? `active_filter_tag ${styles.active_filter_tag}`
      : "";
  };

  return (
    <section
      className={`notes_container ${styles.notes_container} ${isAPIError ? styles.full_width : ""}`}
    >
      {getIsLoadingNotes() && (
        <ABOBackgroundLoader num={5} heading={t(titleAPInfoNotes)} />
      )}
      {!getIsLoadingNotes() && isAPIError && (
        <>
          <div className={styles.heading_area}>
            <div
              className={styles.title}
              data-testid="progress_notes_bg_heading_testID"
            >
              {t(titleAPInfoNotes)}
            </div>
          </div>
          <div data-testid="error_testID">
            <ErrorInPageScreen
              handleClickTryAgain={() => {
                setIsAPIError(false);
              }}
            />
          </div>
        </>
      )}
      {!getIsLoadingNotes() && !isAPIError && (
        <>
          {" "}
          <div className={styles.heading_area}>
            <div
              className={styles.title}
              data-testid="progress_notes_bg_heading_testID"
            >
              {t(titleAPInfoNotes)}
            </div>
          </div>
          <div className={styles.input_wrapper}>
            {isEditable ? (
              <>
                <div className={styles.tags_container}>
                  <TagDropdown
                    getTags={noteTaglist}
                    onChangeTags={handleChangeTags}
                  />
                </div>
                <div className={styles.formatter_wrapper}>
                  <FormatterBar
                    id="notes"
                    testId="notes_testID"
                    editorData={""}
                    handleDataChange={textAreaChangeHandler}
                    section={"notes"}
                    placeholder={t(addInternalNote)}
                    isContentEditable={true}
                    maxCharacter={1000}
                  />
                </div>
                <div className={styles.btn_wrapper}>
                  <Button
                    id="cancelButton"
                    onClick={handleClickCancel}
                    label={t("AP_info.btnCancel")}
                    style="unfilled"
                  />
                  <Button
                    id="saveButton"
                    onClick={() => handleClickSave()}
                    label={t("AP_info.btnSave")}
                    style="filled"
                    isDisabled={!saveEnable || !textareaValue}
                  />
                </div>
              </>
            ) : (
              <>
                <input
                  type="text"
                  className={`input_text_area ${styles.input_text_area}`}
                  placeholder={t(addInternalNote)}
                  value={t(addInternalNote)}
                  onClick={focusHandler}
                  readOnly
                  data-testid="textarea_testID"
                />
              </>
            )}
            <div
              className={`note_and_sort_wrapper ${styles.note_and_sort_wrapper}`}
            >
              <div className={styles.note_count}>
                {APNotesList?.length} <span>Notes</span>
              </div>
              <div
                className={`sort_section ${styles.sort_section}`}
                onClick={handleClickSortIcon}
              >
                <div className={styles.sort_label}>
                  {selectedSort?.id === "newest_first"
                    ? t("AP_info.newest_first")
                    : t("AP_info.oldest_first")}
                </div>
                <img src={sortIcon} alt="" className={styles.sort_icon} />
                {isOpenPopup && (
                  <div
                    ref={dropdownRef}
                    className={`dropdown_container ${styles.dropdown_container}`}
                  >
                    <div className={`dropdown_menu ${styles.dropdown_menu}`}>
                      {map(NOTES_SORT_LIST, (filterItem) => (
                        <div
                          key={filterItem.id}
                          className={`${selectedSort?.id === filterItem?.id ? "dropdown_selected_item" : ""} dropdown_item ${styles.dropdown_item}`}
                          onClick={() => handleChangeSort(filterItem)}
                        >
                          {filterItem.id === "newest_first"
                            ? t("AP_info.newest_first")
                            : t("AP_info.oldest_first")}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.search_bar}>
              <APNotesSearchBar
                placeholder={t("AP_info.search_notes")}
                searchAPNotes={searchAPNotes}
              />
            </div>
            <div
              className={`filter_head_wrapper ${styles.filter_head_wrapper}`}
            >
              {map(noteTaglist, (filterTag, index) => (
                <div
                  key={index}
                  className={`filter_tag ${styles.filter_tag} ${getConditionalFilterTagClass(filterTag)}`}
                  onClick={() => {
                    filterTagHandler(filterTag);
                  }}
                >
                  {activeFilterTag === filterTag && (
                    <Icon
                      name={"selected_tick_circle"}
                      size={18}
                      className={styles.tag_icon}
                      color={currentTheme === "light" ? "white" : "black"}
                    />
                  )}
                  {filterTag}
                </div>
              ))}
            </div>
            {isEmpty(APNotesList) && (
              <div className={styles.empty_state_document}>
                <div className={`table_no_data ${styles.empty_state_msg}`}>
                  {t("AP_info.no_notesFound")}
                </div>
              </div>
            )}
          </div>
          {showToast && (
            <ToastMessage
              message={toastMessage}
              subText=""
              toastType="success"
              showToast={showToast}
              setShowToast={setShowToast}
            />
          )}
          {map(APNotesList, (note) => (
            <NoteItem
              key={note.apNoteId}
              note={note}
              isNoteEditable={isNoteEditable}
              editNoteId={editNoteId}
              usersListByNativeId={usersListByNativeId}
              userAffiliateCode={userAffiliateCode}
              onEdit={handleNoteEdit}
              onDelete={handleNoteDelete}
              onSave={(id, val, tagVal) => handleClickSave(id, val, tagVal)}
              onCancel={handleNoteCancel}
              getTags={noteTaglist}
              onChangeTags={(tags) => handleChangeTags(tags)}
              isSourceAP={true}
              saveBtnDisabled={saveEnable}
            />
          ))}
        </>
      )}
      {
        <Modal showDrawer={modalOpen} onClose={() => setModalOpen(false)}>
          <DeleteConfirmationPopUp
            id={"successionPlanDocDeleteConfirmation"}
            titleLabel={"Delete Item?"}
            msgText={
              "Are you sure you want to permanently delete this item? This action cannot be undone."
            }
            buttonLabelOne={t("No, Cancel")}
            buttonLabelTwo={t("Yes, Delete")}
            onCancel={handleDeleteModalToggle}
            onDelete={confirmDeleteNote}
            customStyle={customButtonStyles}
          />
        </Modal>
      }
    </section>
  );
};

export default APNotes;
