import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "./axiosBaseQuery/axiosBaseQuery";
import config from "../../config/config";

export const AwardHistoryAPI = createApi({
  reducerPath: "awardHistoryAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: config?.appUrl,
  }),
  endpoints: (builder) => ({
    getAwardHistory: builder.query<any, UserSpecificArgTypes>({
      query: ( {affiliateCode, aboNumber }) => ({
        url: `/am/abo/${affiliateCode}/${aboNumber}/awardhistory`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetAwardHistoryQuery } = AwardHistoryAPI;
