import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../redux/hooks";

import dropdownArrowLight from "../../../../assets/images/LightTheme/down_arrow_light.svg";
import dropdownArrowDark from "../../../../assets/images/DarkTheme/down_arrow_dark.svg";
import useClickOutside from "../../../CustomHooks/useClickOutside";
import styles from "./AccountListDropDown.module.scss";
import map from "lodash/map";
import Checkbox from "../../CustomCheckbox/CustomCheckbox";
import Icon from "../../Icon/Icon";
import { debounce, isEmpty } from "lodash";
import { truncate } from "lodash";

interface AccountListDropDownProps {
  onABOSelection: (items: SelectedItem[]) => void;
  recentSearchListData: ReportListData[];
  reportAboListData: ReportListData[];
  onABOSearch: (searchTerm: string) => void;
  isLoadingGetReportsAboList: boolean;
  isRecentSearchABOListLoading: boolean;
}
const AccountListDropDown: React.FC<AccountListDropDownProps> = ({
  onABOSelection,
  recentSearchListData,
  reportAboListData,
  onABOSearch,
  isLoadingGetReportsAboList,
  isRecentSearchABOListLoading
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const dropdownArrow =
    currentTheme === "light" ? dropdownArrowLight : dropdownArrowDark;
  let arr: number[] = new Array(4).fill(0);
  const [selectedItem, setSelectedItem] = useState<any[]>([]);
  const [ABONameList, setABONameList] = useState<any[]>(reportAboListData);
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (searchTerm === '') {
      setABONameList(isEmpty(recentSearchListData) ? reportAboListData : recentSearchListData);
    } else {
      setABONameList(reportAboListData);
    }

  }, [reportAboListData, recentSearchListData]);

  const handleSelectItem = (clickedItem: { localName: string; aboNumber: string | number }) => {
    const isSelected = selectedItem?.some(
      item => item.localName === clickedItem.localName && item.aboNumber === clickedItem.aboNumber
    );

    let newlistData: typeof selectedItem;
    if (isSelected) {
      newlistData = selectedItem?.filter(
        item => !(item?.localName === clickedItem?.localName && item?.aboNumber === clickedItem?.aboNumber)
      );
      setSelectedItem(newlistData);
      onABOSelection(newlistData);
    } else {
      // Add the item to the selection
      newlistData = [...selectedItem, clickedItem];
      setSelectedItem(newlistData);
      onABOSelection(newlistData);
    }
  };

  const dropdownRef = useRef<HTMLDivElement>(null);
  useClickOutside(dropdownRef, () => setIsOpen(false));

  const searchDebounceABOList = useCallback(
    debounce((term: string) => {
      if (term?.trim()?.length > 2 || (term?.trim()?.length === 0)) {
        onABOSearch(term);
      }
    }, 600),
    [onABOSearch]
  );

  useEffect(() => {
    return () => {
      searchDebounceABOList.cancel();
    };
  }, [searchDebounceABOList]);

  const handleSearchABO = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
    searchDebounceABOList(term);
  };
  const clearSearchHandler = () => {
    if (searchTerm) {
      setSearchTerm("");
      searchDebounceABOList("");
    }
  }
  const getMoreTextWithNames = () => {
    const trimmedLocalName = truncate(selectedItem[0]?.localName, { length: 38, omission: " ..." });
    return selectedItem?.length > 1 ? `${trimmedLocalName}, +${selectedItem?.length - 1} ${t("more")}` : selectedItem?.length === 1 ? selectedItem[0]?.localName : '';
  }

  return (
    <div ref={dropdownRef} className={`dropdown ${styles.dropdown}`}>
      <div
        className={styles.input_wrapper}
        onClick={toggleDropdown}
        data-testid="toggle_drodpwn_testId"
      >
        <input
          type="text"
          className={`dropdown_input ${styles.dropdown_input} ${isOpen ? "selected_input" : ""}`}
          value={getMoreTextWithNames()}
          readOnly
          data-testid="dropdown_input_testId"
          placeholder={t("report_dropdown_placeholder")}
        />
        <img
          src={dropdownArrow}
          alt="Downkey"
          className={`${styles.arrow_icon} ${isOpen ? styles.rotate : ""}`}
        />
      </div>

      {isOpen && (
        <div
          className={`dropdown_menu ${styles.dropdown_menu} ${styles.dropdown_pos}`}
          data-testid="report_abo_list_testId"
        >
          <>
            <div className={`search_bar_container ${styles.search_bar_container}`}>
              <Icon
                name="search"
                size={18}
                color={currentTheme === 'light' ? '#2c2c2c' : '#AEAEB2'}
                className={styles.search_icon}
              />
              <input
                className={`search_input ${styles.search_input}`}
                type="text"
                placeholder={t("report_search_placeholder")}
                value={searchTerm}
                onChange={handleSearchABO}
              />
              {searchTerm?.length > 0 && <span className={styles.clear_search}>
                <Icon
                  name="cross_icon_small"
                  size={10}
                  color={currentTheme === 'light' ? '#2c2c2c' : '#D8D8D9'}
                  className={styles.clear_search_icon}
                  onClick={clearSearchHandler}
                />
              </span>}
            </div>
            {isLoadingGetReportsAboList || isRecentSearchABOListLoading
              ? arr.map((_, index) => (
                <div
                  key={index}
                  className={`${styles.dropdown_item} ${styles.loader} animate`}
                ></div>
              )) : (
                ABONameList.length > 0 ?
                  <div className={`${styles.dropdown_list} ${(isLoadingGetReportsAboList || isRecentSearchABOListLoading) ? styles.dropdown_loader : ""}`}>
                    {(map(ABONameList, (item, index) => (
                      <div
                        key={item?.aboNumber ?? index}
                        className={`dropdown_item ${styles.dropdown_item} ${selectedItem?.some(
                          selected => selected?.localName === item?.localName && selected?.aboNumber === item?.aboNumber
                        ) ? `selected ${styles.selected}` : ""}`}
                      >
                        <div className={styles.checkbox_wrapper}>
                          <Checkbox
                            checked={selectedItem?.some(
                              selected => selected?.localName === item?.localName && selected?.aboNumber === item?.aboNumber
                            )}
                            label={`${item?.localName} #${item.aboNumber}`}
                            checkboxHandler={() => handleSelectItem(item)}
                            isDisabled={false}
                            screenName='reportbuilder'
                          />
                        </div>
                      </div>
                    )))}
                  </div>
                  : (
                    searchTerm?.length > 0 ? <div className={`no_result_text ${styles.no_result_text}`}>{t("no_results_found_for", { value: searchTerm })}</div> : ''
                  )
              )
            }
          </>
        </div>
      )}
    </div>
  );
};

export default AccountListDropDown;
