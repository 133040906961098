import React, { useEffect, useState } from "react";
import styles from "./ContactCardInformation.module.scss";
import isEmpty from "lodash/isEmpty";
import PersonalInfoCard from "./PersonalInfoCard/PersonalInfoCard";
import { useTranslation } from "react-i18next";
import { ICard } from "../../../../types/types";
import { useGetAboContactCardDetailsQuery } from "../../../../redux/services/aboContactCardDetailsAPI";
import { useAppSelector } from "../../../../redux/hooks";
import ContactInfoLoader from "./ContactInfoLoader/ContactInfoLoader";
import ErrorInPageScreen from "../../../common/ErrorInPageScreen/ErrorInPageScreen";
import { isValidResponse } from "../../../../utils/validateAPIResponse";
import { useProfileParams } from "../../../../utils/common";
import dropdownArrowLight from "../../../../assets/images/LightTheme/down_arrow_light.svg";
import dropdownArrowDark from "../../../../assets/images/DarkTheme/down_arrow_dark.svg";

const ContactCardInformation = () => {
  const { t } = useTranslation();
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const dropdownArrow =
    currentTheme === "light" ? dropdownArrowLight : dropdownArrowDark;
  const [contactCardData, setContactCardData] = useState<ICard[] | []>([]);
  const [isViewmore, setViewMore] = useState<boolean>(false);
  const userDetails = useAppSelector((state: any) => state.userDetails.user);
  const [isAPIError, setIsAPIError] = useState(false);
  const [isContentVisible, setIsContentVisible] = useState<boolean>(true);
  const [width, setWidth] = useState<number>(window.innerWidth);

  const { affiliateCode, aboNumber } = useProfileParams();
  const { isFetching, data, isError } = useGetAboContactCardDetailsQuery(
    {
      affiliateCode,
      aboNumber,
    },
    {
      skip: isAPIError,
      refetchOnMountOrArgChange: true,
    }
  );

  const handleAPIError = () => {
    setIsAPIError(true);
    setContactCardData([]);
  };

  useEffect(() => {
    isError && handleAPIError();
  }, [isError]);

  useEffect(() => {
    if (!isEmpty(data)) {
      if (isValidResponse(data?.statusCode)) {
        setContactCardData(data?.body ?? []);
      } else {
        handleAPIError();
      }
    }
  }, [data, isFetching]);

  const handleDropdownClick = () => {
    setIsContentVisible((prevState) => !prevState);
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isTablet = width <= 991;

  return (
    <div className={`contact_info_wrapper ${styles.contact_info_wrapper}`}>
      <div
        className={styles.section_title_wraper}
        style={{
          marginBottom: isContentVisible ? "16px" : "",
        }}
      >
        <label
          className={`content_inner_label ${styles.section_label}`}
          data-testid="contact_card_section_heading_testID"
        >
          {t("contact_information")}
        </label>
        <img
          src={dropdownArrow}
          alt="Dropdown Arrow"
          className={`dropdown_icon ${styles.dropdown_icon} ${isContentVisible ? styles.rotate : ""}`}
          onClick={handleDropdownClick}
          style={{
            cursor: true ? "pointer" : "default",
          }}
        />
      </div>
      {isContentVisible && (
        <div className={styles.card_holder}>
          {isFetching ? (
            <ContactInfoLoader cardNumber={4} />
          ) : isAPIError ? (
            <ErrorInPageScreen
              handleClickTryAgain={() => {
                setIsAPIError(false);
              }}
            />
          ) : (
            contactCardData?.length > 0 &&
            contactCardData
              ?.slice(0, isViewmore ? 10 : 4)
              ?.map((card, index) => (
                <PersonalInfoCard
                  key={`${card.name || "" + index}`}
                  showBorderRight={isTablet ? (index + 1) % 2 !== 0 : (index + 1) % 4 !== 0}
                  {...card}
                />
              ))
          )}
          {contactCardData?.length > 4 && (
            <div className={styles.viewmore_Section}>
              <button
                className={`view_more_text ${styles.viewmore_label}`}
                onClick={() => setViewMore(!isViewmore)}
                data-testid="view_more_testId"
              >
                {isViewmore ? t("view_less") : t("view_more")}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ContactCardInformation;
