import React from "react";
import styles from "./PersonalInformation.module.scss";
import ContactCardInformation from "./ContactCardInformation/ContactCardInformation";
import ABOBackground from "./ABOBackground/ABOBackground";
import SuccessionPlan from "./SuccessionPlan/SuccessionPlan";
import BusinessQualitativeComponent from "./BusinessQualitative/BusinessQualitative";
import AwardQualification from "./AwardQualification/AwardQualification";

interface PersonalInformationProps { }

const PersonalInformation: React.FC<PersonalInformationProps> = ({ }) => {
  return (
    <div
      className={styles.personal_info_wrapper}
      data-testid="personal_information_section_testID"
    >
      <div className={`content_inner ${styles.inner_wrapper}`}>
        <div className={styles.section_wrapper}>
          <ContactCardInformation />
        </div>
        <div className={styles.section_wrapper}>
          <AwardQualification />
        </div>
        <div className={styles.section_wrapper}>
          <BusinessQualitativeComponent />
        </div>
        <div className={styles.verticalSection}>
          <div className={styles.vsection_wrapper}>
            <ABOBackground />
          </div>
          <div className={`separator_line ${styles.separator_line}`} />
          <div className={styles.vsection_wrapper}>
            <SuccessionPlan />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
