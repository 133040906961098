import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import styles from "./BusinessQualitative.module.scss";
import { useAppSelector } from "../../../../redux/hooks";
import {
  useGetAboQualitativeDataQuery,
  useUpdateAboQualitativeDataMutation,
} from "../../../../redux/services/aboQualitativeAPI";
import { formatDate } from "../../../../utils/dateFormatUtil";
import Button from "../../../common/Button/Button";
import FormatterBar from "../../../common/FormatterBar/FormatterBar";
import HTMLtoText from "../../../common/HTMLToText/HTMLToText";
import ABOBackgroundLoader from "../../../common/Loaders/ABOBackgroundLoader/ABOBackgroundLoader";
import ErrorInPageScreen from "../../../common/ErrorInPageScreen/ErrorInPageScreen";
import { isValidResponse } from "../../../../utils/validateAPIResponse";
import { useProfileParams } from "../../../../utils/common";
import RadioButton from "../../../common/RadioButton/RadioButton";

function BusinessQualitativeComponent() {
  const { t } = useTranslation();
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const { affiliateCode, aboNumber } = useProfileParams();
  const [ABOQualitativeData, setABOQualitativeData] =
    useState<IQualitativeObject | null>(null);
  const [updatedABOQualitativeData, setUpdatedABOQualitativeData] =
    useState<IQualitativeObjectPayload | null>(null);
  const [isEditable, setIsEditable] = useState(false);
  const [isAPIError, setIsAPIError] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>("yes");

  const handleDataChange = (editorData: any, section: string) => {
    setUpdatedABOQualitativeData((updatedABOQualitativeData) => ({
      ...updatedABOQualitativeData,
      [section]: editorData,
    }));
  };

  const { data, isFetching, isError } = useGetAboQualitativeDataQuery(
    {
      affiliateCode,
      aboNumber,
    },
    {
      skip: Number(affiliateCode) === 0 || aboNumber === 0 || isAPIError,
      refetchOnMountOrArgChange: true,
    }
  );

  const handleAPIError = () => {
    setIsAPIError(true);
    setABOQualitativeData(null);
    setUpdatedABOQualitativeData(null);
    setSelectedOption("yes");
  };

  useEffect(() => {
    isError && handleAPIError();
  }, [isError]);

  useEffect(() => {
    if (!isEmpty(data)) {
      if (isValidResponse(data?.statusCode)) {
        setABOQualitativeData(data?.body ?? {});
        setUpdatedABOQualitativeData(data?.body ?? {});
        setSelectedOption(data?.body?.has_succession ? "yes" : "no");
      } else {
        handleAPIError();
      }
    }
  }, [data, isFetching]);

  const [
    updateABOBackgroundDetails,
    { isLoading: isLoadingUpdateABOQualitativeData, isError: isErrorInSave },
  ] = useUpdateAboQualitativeDataMutation();

  useEffect(() => {
    isErrorInSave && handleAPIError();
  }, [isErrorInSave]);

  const handleClickEdit = () => {
    setIsEditable(true);
  };

  const handleClickCancel = () => {
    setIsEditable(false);
    setUpdatedABOQualitativeData(ABOQualitativeData);
  };

  const handleClickSave = async () => {
    const body = {
      how_build_business: updatedABOQualitativeData?.how_build_business ?? "",
      how_promote_business:
        updatedABOQualitativeData?.how_promote_business ?? "",
      how_prospect: updatedABOQualitativeData?.how_prospect ?? "",
      who_prospect: updatedABOQualitativeData?.who_prospect ?? "",
      how_activate: updatedABOQualitativeData?.how_activate ?? "",
      what_community: updatedABOQualitativeData?.what_community ?? "",
      has_succession: selectedOption === "yes" ? true : false,
    };

    const response: any = await updateABOBackgroundDetails({
      affiliateCode,
      aboNumber,
      payload: body,
    });

    if (isValidResponse(response?.data?.statusCode)) {
      setIsEditable(false);
      setABOQualitativeData(response?.data?.body);
      setUpdatedABOQualitativeData(response?.data?.body);
    } else {
      handleAPIError();
    }
  };

  return (
    <div
      className={`abo_bg_container ${styles.business_philosophy_container} ${isAPIError ? styles.full_width : ""}`}
    >
      {isFetching || isLoadingUpdateABOQualitativeData ? (
        <ABOBackgroundLoader num={5} heading={t("business_philosophy_title")} />
      ) : isAPIError ? (
        <>
          <div className={styles.heading_area}>
            <span
              className={styles.heading}
              data-testid="abo_bg_heading_testID"
            >
              {t("business_philosophy_title")}
            </span>
          </div>
          <ErrorInPageScreen
            handleClickTryAgain={() => {
              setIsAPIError(false);
            }}
          />
        </>
      ) : (
        <>
          <div className={styles.business_qaulitative_header}>
            <div className={`${styles.header_wrapper}`}>
              <div className={styles.heading_area}>
                <span
                  className={styles.heading}
                  data-testid="abo_bg_heading_testID"
                >
                  {t("business_philosophy_title")}
                </span>
                <span className={styles.right_section}>
                  {!isEditable && (
                    <span
                      className={styles.edit_section}
                      onClick={handleClickEdit}
                      data-testid="edit_testID"
                    >
                      <span
                        className={
                          currentTheme === "light"
                            ? styles.edit_icon_light
                            : styles.edit_icon_dark
                        }
                      ></span>
                      <span>{t("edit")}</span>
                    </span>
                  )}
                </span>
              </div>
              <div className={`grey_text ${styles.sub_heading}`}>
                {t("business_philosophy_sub_title")}
              </div>
              <div className={`grey_text ${styles.last_modified}`}>
                {t("last_modified")}{" "}
                {formatDate(ABOQualitativeData?.audit?.updatedDate)}
              </div>
            </div>
            {isEditable && (
              <div className={styles.btn_wrapper}>
                <Button
                  id="cancelButton"
                  onClick={handleClickCancel}
                  label={t("btnCancel")}
                  style="unfilled"
                />
                <Button
                  id="saveButton"
                  onClick={handleClickSave}
                  label={t("btnSave")}
                  style="filled"
                />
              </div>
            )}
          </div>
          <div className={`details_wrapper ${styles.details_wrapper}`}>
            <div className={`flex_wrapper ${styles.flex_wrapper}`}>
                <div className={`item_wrapper ${styles.item_wrapper}`}>
                  {isEditable ? (
                    <FormatterBar
                      id="formatter-bar-business"
                      testId="formatter-bar-how-do-they-build-their-own-business"
                      label={t("business_philosophy.questions.question1")}
                      editorData={ABOQualitativeData?.how_build_business || ""}
                      handleDataChange={handleDataChange}
                      section={"how_build_business"}
                      isContentEditable={false}
                      maxCharacter={2000}
                    />
                  ) : (
                    <>
                      <div
                        className={styles.item_name}
                        data-testid="abo_bg_item_testID"
                      >
                        {t("business_philosophy.questions.question1")}
                      </div>
                      <div className={`grey_text ${styles.item_value}`}>
                        <HTMLtoText
                          htmlContent={ABOQualitativeData?.how_build_business || ""}
                          alternateMessage={t("no_info_available")}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className={`item_wrapper ${styles.item_wrapper}`}>
                  {isEditable ? (
                    <FormatterBar
                      id="how_promote_business"
                      testId="education_testID"
                      label={t("business_philosophy.questions.question2")}
                      editorData={ABOQualitativeData?.how_promote_business || ""}
                      handleDataChange={handleDataChange}
                      section={"how_promote_business"}
                      isContentEditable={false}
                      maxCharacter={2000}
                    />
                  ) : (
                    <>
                      <div
                        className={styles.item_name}
                        data-testid="abo_bg_item_testID"
                      >
                        {t("business_philosophy.questions.question2")}
                      </div>
                      <div className={`grey_text ${styles.item_value}`}>
                        <HTMLtoText
                          htmlContent={
                            ABOQualitativeData?.how_promote_business || ""
                          }
                          alternateMessage={t("no_info_available")}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className={`item_wrapper ${styles.item_wrapper}`}>
                  {isEditable ? (
                    <FormatterBar
                      id="how_prospect"
                      testId="family_bg_testID"
                      label={t("business_philosophy.questions.question3")}
                      editorData={ABOQualitativeData?.how_prospect || ""}
                      handleDataChange={handleDataChange}
                      section={"how_prospect"}
                      isContentEditable={false}
                      maxCharacter={2000}
                    />
                  ) : (
                    <>
                      <div
                        className={styles.item_name}
                        data-testid="abo_bg_item_testID"
                      >
                        {t("business_philosophy.questions.question3")}
                      </div>
                      <div className={`grey_text ${styles.item_value}`}>
                        <HTMLtoText
                          htmlContent={ABOQualitativeData?.how_prospect || ""}
                          alternateMessage={t("no_info_available")}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className={`item_wrapper ${styles.item_wrapper}`}>
                  {isEditable ? (
                    <FormatterBar
                      id="who_prospect"
                      testId="hobby_testID"
                      label={t("business_philosophy.questions.question4")}
                      editorData={ABOQualitativeData?.who_prospect || ""}
                      handleDataChange={handleDataChange}
                      section={"who_prospect"}
                      isContentEditable={false}
                      maxCharacter={2000}
                    />
                  ) : (
                    <>
                      <div
                        className={styles.item_name}
                        data-testid="abo_bg_item_testID"
                      >
                        {t("business_philosophy.questions.question4")}
                      </div>
                      <div className={`grey_text ${styles.item_value}`}>
                        <HTMLtoText
                          htmlContent={ABOQualitativeData?.who_prospect || ""}
                          alternateMessage={t("no_info_available")}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className={`flex_wrapper ${styles.flex_wrapper}`}>
                <div className={`item_wrapper ${styles.item_wrapper}`}>
                  {isEditable ? (
                    <FormatterBar
                      id="how_activate"
                      testId="other_testID"
                      label={t("business_philosophy.questions.question5")}
                      editorData={ABOQualitativeData?.how_activate || ""}
                      handleDataChange={handleDataChange}
                      section={"how_activate"}
                      isContentEditable={false}
                      maxCharacter={2000}
                    />
                  ) : (
                    <>
                      <div
                        className={styles.item_name}
                        data-testid="abo_bg_item_testID"
                      >
                        {t("business_philosophy.questions.question5")}
                      </div>
                      <div className={`grey_text ${styles.item_value}`}>
                        <HTMLtoText
                          htmlContent={ABOQualitativeData?.how_activate || ""}
                          alternateMessage={t("no_info_available")}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className={`item_wrapper ${styles.item_wrapper}`}>
                  {isEditable ? (
                    <FormatterBar
                      id="what_community"
                      testId="other_testID"
                      label={t("business_philosophy.questions.question6")}
                      editorData={ABOQualitativeData?.what_community || ""}
                      handleDataChange={handleDataChange}
                      section={"what_community"}
                      isContentEditable={false}
                      maxCharacter={2000}
                    />
                  ) : (
                    <>
                      <div
                        className={styles.item_name}
                        data-testid="abo_bg_item_testID"
                      >
                        {t("business_philosophy.questions.question6")}
                      </div>
                      <div className={`grey_text ${styles.item_value}`}>
                        <HTMLtoText
                          htmlContent={ABOQualitativeData?.what_community || ""}
                          alternateMessage={t("no_info_available")}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className={`item_wrapper ${styles.item_wrapper}`}>
                  <div
                    className={`${isEditable ? `have_succession ${styles.have_succession}` : ""}`}
                  >
                    <div className={styles.succession_question_text}>
                    {t("business_philosophy.questions.question7")}
                    </div>
                    {isEditable ? (
                      <div className={`options ${styles.options}`}>
                        <RadioButton
                          label={t("yes")}
                          name="successionPlan"
                          value="yes"
                          selectedValue={selectedOption}
                          onChange={setSelectedOption}
                        />
                        <RadioButton
                          label={t("no")}
                          name="successionPlan"
                          value="no"
                          selectedValue={selectedOption}
                          onChange={setSelectedOption}
                        />
                      </div>
                    ) : (
                      <div className={`grey_text ${styles.item_value}`}>
                        {selectedOption === "yes" ? t("yes") : t("no")}
                      </div>
                    )}
                  </div>
                </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default BusinessQualitativeComponent;
