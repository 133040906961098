import { isEmpty, isNull } from "lodash";
import {
  SortOrder,
  InputData,
  IOutputData
} from "../types/types";

import { urlConfiguration } from "../config/urlConfiguration";
import axiosInstance from "../config/interceptors/axios.interceptor";
import { useParams } from "react-router-dom";

// Get initials of passed string
export const getInitials = (fullName: String) => {
  const words = fullName?.split(" ");
  let initials = "";
  for (let i = 0; i < words?.length && i < 3; i++) {
    initials += words[i].replace(/[^a-zA-Zก-๙]/g, "").charAt(0);
  }
  return initials;
};

// convert utc date to format like [12-04-22 3:30pm]
export const formatDate = (date: string) => {
  // Extracting components from the date
  const newDate = new Date(date);
  const day = newDate.getDate()?.toString()?.padStart(2, "0");
  const month = (newDate.getMonth() + 1)?.toString()?.padStart(2, "0");
  const year = newDate?.getFullYear()?.toString()?.slice(-2);

  const minutes = newDate?.getMinutes()?.toString().padStart(2, "0");
  const hours = newDate.getHours() % 12 || 12; // Convert to 12-hour format
  const ampm = newDate.getHours() >= 12 ? "pm" : "am";

  // Constructing the formatted string
  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}${ampm}`;

  return formattedDate;
};

function compareValues(valueA: string | number, valueB: string | number): number {
  // Handle numbers
  if (typeof valueA === 'number' && typeof valueB === 'number') {
    return valueA - valueB;
  }

  const stringA = String(valueA)?.toLowerCase();
  const stringB = String(valueB)?.toLowerCase();

  if (stringA === stringB) {
    return 0;
  }

  return stringA < stringB ? -1 : 1;
}

export const sortTableData = <T>(
  data: T[],
  column: keyof T,
  order: SortOrder = "desc",
): T[] => {

  if (!["asc", "desc"].includes(order)) {
    console.log("Order parameter must be 'asc' or 'desc'");
    return data;
  }

  if (!data[0]?.hasOwnProperty(column)) {
    console.error("Column not found in data");
  }

  return data?.sort((a, b) => {
    const valueA: any = a[column];
    const valueB: any = b[column];


    // Use the comparison function to compare values
    if (order === "asc") {
      return compareValues(valueA, valueB);
    } else {
      return compareValues(valueB, valueA); // Reverse order for descending
    }

  });
}

function flattenObject(obj: any, keysToFind: string[]): any {
  let results: any = null;

  function findHelper(obj: any) {
    for (const key in obj) {
      if (keysToFind?.includes(key)) {
        const result = { [key]: obj[key] };
        results = { ...results, ...result };
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        findHelper(obj[key]);
      }
    }
  }

  findHelper(obj);
  return results;
}

export function findKeysInArray(arr: any[], keysToFind: string[]): any[] {
  const newArr: any = [];
  arr?.length > 0 && arr?.map((obj: any) => {
    if (!isNull(obj)) {
      const flattenObj = flattenObject(obj, keysToFind);
      newArr?.push(flattenObj);
    }
  })
  return newArr;
}

//--- Account Table Utility function

export function getNestedValue<T>(obj: T, path: string): any {
  return path?.split('.')?.reduce((acc: any, part: string) => acc && acc[part], obj);
}

export function sortAccountTableData<T>(data: T[], path: string, order: SortOrder): T[] {
  // Check if the sort order is 'nosort'
  if (order === "nosort" || isEmpty(data)) {
    return data;
  }

  // Check if the column exists in the first row of the data
  if (data?.length > 0 && getNestedValue(data[0], path) === undefined) {
    console.warn(`Column path "${path}" does not exist in the data.`);
    return data;
  }

  let arrayForSort = [...data];
  return arrayForSort?.sort((a, b) => {
    const valueA: any = getNestedValue(a, path);
    const valueB: any = getNestedValue(b, path);

    if (order === "asc") {
      return compareValues(valueA, valueB);
    } else {
      return compareValues(valueB, valueA); // Reverse order for descending
    }
  });
}

// US phone number format 
export const formatPhoneNumber = (phoneNumber: string | number | undefined, phoneId: string | number | undefined, phoneArea: string | number | undefined, phoneExtension: string | number | undefined) => {
  // If empty string - return 
  if (!phoneNumber) {
    return phoneNumber;
  }
  // Remove all non-digit characters
  const cleaned: any = ('' + phoneArea + phoneNumber).replace(/\D/g, '');
  // Check if the input is of correct length
  if (cleaned?.length !== 10) {
    console.error("Invalid phone number length. Expected 10 digits.");
  }
  // Format the number
  const formattedPhoneNumber: any = `${phoneId ? "+" + phoneId : ''}${phoneId == "1" && cleaned?.length === 10 ? ` ${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6)}` :
    `${cleaned.slice(0, 3)} ${cleaned.slice(3, 6)} ${cleaned.slice(6)}`}`; // Add space between sliced if needed-AMW-460

  const cleanedExtension = ('' + phoneExtension).replace(/\D/g, '');
  const formattedPhoneNumberWithExtension = formattedPhoneNumber + (cleanedExtension ? ', ' + cleanedExtension : '');

  return formattedPhoneNumberWithExtension;
}

export const formatUSPostalCode = (postalCode: string | number | undefined, primaryAddressCountry: string | undefined) => {
  // If empty string - return 
  if (!postalCode) {
    return postalCode || "";
  }
  // Remove all non-digit characters
  const cleaned: any = ('' + postalCode).replace(/\D/g, '');
  // Check if the input is of correct length
  if (cleaned?.length !== 9) {
    console.error("Invalid postal code length. Expected 9 digits.");
  }
  // Format the postal code
  const formatted = `${cleaned?.length > 5 && primaryAddressCountry?.toLowerCase() === "us" ? (cleaned?.slice(0, 5) + "-" + cleaned?.slice(5)) : cleaned}`;
  return formatted;
}

// Determine translation keys based on screenName
export const translateKey = (key: string, screenName?: string) => {
  switch (screenName) {
    case "apInformation":
      return `AP_info.${key}`;
    default:
      return key;
  }
};

export const downloadDocument = async (type: string) => {
  const URL = `${urlConfiguration.baseUrl}/admin/v2/document/type/${type}`;
  try {
    const response = await axiosInstance.get(URL);
    //const dataBlob = new Blob([response.data], { type: "application/pdf" });
    //const url = window.URL.createObjectURL(dataBlob);
    const url = response?.data?.body?.path;
    if (type === 'playbook') {
      window.open(url, "_blank");
      return;
    }
    //OPEN in new tab
    // if (isDocType === 'pdf') {
    //   window.open(url, "_blank");
    //   return;
    // }
    const tempLink = document.createElement("a");
    tempLink.href = url;
    tempLink.setAttribute(
      "download",
      `${'Playbook'}`
    );
    document.body.appendChild(tempLink);
    tempLink.click();

    // Clean up the temporary elements and URL
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading Playbook Docs:", error);
  }
};

export const transformMyAccountAPIRespData = async (inputData: InputData): Promise<IOutputData> => {
  try {
    // Simulating async processing (Replace with actual API call if needed)
    return Promise.resolve(transformData(inputData));
  } catch (error) {
    console.error("Error transforming MyAccount V2 API data:", error);
    throw error;
  }
};
export const transformData = (data: InputData): IOutputData => {
  return {
    abos: data?.abos?.map((aboKPI) => {
      const transformedKpis: Record<string, any> = {
        aboNumber: aboKPI.aboNumber,
        name: aboKPI.name,
        affiliateCode: aboKPI.affiliateCode,
        localName: aboKPI.localName,
        marketCountryCode: aboKPI.marketCountryCode,
        marketName: aboKPI.marketName,
        currentAward: aboKPI.currentAward,
        trackingAward: aboKPI.trackingAward,
        primaryAccountManager: aboKPI.primaryAccountManager
      };
      if (aboKPI.kpis) {
        aboKPI?.kpis?.forEach((kpi) => {
          transformedKpis[kpi.kpiCode] = kpi;
        });
      }

      return transformedKpis;
    }),
    closingMonth: data?.closingMonth,
    pagination: data?.pagination
  };
};

export const convertUrlsToLinks = (content: string, theme: 'light' | 'dark'): string => {
  if (!content) return content;

  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = content;

  const processNode = (node: Node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      const urlRegex = /(https?:\/\/[^\s<>"']+)/g;
      const text = node.nodeValue || "";

      if (urlRegex.test(text)) {
        // Replace URLs with anchor tags
        const newHtml = text.replace(urlRegex, (url) => {
          return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="custom-text">${url}</a>`;
        });

        // Create a span wrapper
        const wrapper = document.createElement("span");
        wrapper.innerHTML = newHtml;

        // Replace text node with multiple nodes (safe alternative to replaceWith)
        if (node.parentNode) {
          node.parentNode.replaceChild(wrapper, node);
        }
      }
    } else if (node.nodeType === Node.ELEMENT_NODE && (node as HTMLElement).tagName !== "A") {
      // Convert NodeList to an array to avoid iteration issues
      Array.from(node.childNodes).forEach(processNode);
    }
  };

  // Convert NodeList to array to avoid downlevel iteration issues
  Array.from(tempDiv.childNodes).forEach(processNode);

  // Now apply the inline style to anchor tags inside the div if needed
  const anchors = tempDiv.querySelectorAll('a');
  anchors.forEach((anchor) => {
    const linkColor = theme === "light" ? "#5969D6" : "#99B2F5";
    anchor.style.color = linkColor;
  });

  return tempDiv.innerHTML;
};



export const useProfileParams = () => {
  const params = useParams<{ affiliateCode?: string; aboNumber?: string }>();

  return {
    affiliateCode: params.affiliateCode || "",
    aboNumber: Number(params.aboNumber) || 0,
  };
};

export const formatDatetoLong = (dateStr: string) => {
  const date = new Date(dateStr);
  const day = date.getDate();
  const month = date.toLocaleString(`default`, { month: 'short' }); // Nov
  const year = date.getFullYear();

  const formattedDate = `${month} ${day}, ${year}`;  // formatting to Nov 1, 1964 style
  return formattedDate;
}
