import React, { useEffect, useState } from "react";
import styles from "./Income.module.scss";
import { useTranslation } from "react-i18next";
import KPIsAndTilesSection from "../KPIsAndTilesSection/KPIsAndTilesSection";
import SeperatorLineDark from "../../../../assets/images/DarkTheme/seperator_line.svg";
import { useGetKpiSettingsQuery } from "../../../../redux/services/kpiSettingsByTypeAPI";
import KPIsListDataMassager from "../../../CustomHooks/useGetMassagedKPIsList";
import { useAppSelector } from "../../../../redux/hooks";
import { useProfileParams } from "../../../../utils/common";

const Income: React.FC<CategoriesProps> = ({ category }) => {
  const { t } = useTranslation();
  const [incomeKPIsList, setIncomeKPIsList] = useState<any>([]);
  const massagedKPIslist = KPIsListDataMassager(incomeKPIsList ?? []);
  const userDetails = useAppSelector((state: any) => state.userDetails.user);
  const { affiliateCode, aboNumber } = useProfileParams();

  const { data: incomeKpisResponse } = useGetKpiSettingsQuery({
    type: "business-performance-income",
    affiliateCode
  },
    { refetchOnMountOrArgChange: true });

  useEffect(() => {
    const tempKPIslist = incomeKpisResponse?.body;
    setIncomeKPIsList(tempKPIslist);
  }, [incomeKpisResponse]);

  return (
    <div className={styles.income_wrapper}>
      <div className={styles.heading_area}>
        <div className={styles.heading}>{t("income")}</div>
        <div className={`section_sub_heading ${styles.sub_heading}`}>
          {t("income_summary")}
        </div>
      </div>
      <KPIsAndTilesSection
        tileDataList={massagedKPIslist}
        kpiList={massagedKPIslist}
        SeperatorIcon={SeperatorLineDark}
        category={category}
      />
    </div>
  );
};

export default Income;
