import { HttpStatusCode } from "axios";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import config from "../../../config/config";
import authService from "../../../config/auth.service";
import axiosInstance from "../../../config/interceptors/axios.interceptor";
import {
  countryMappings,
  currencyMappings,
} from "../../../constants/dataConstants";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { withUtag } from "../../../utils/analytics";

export const TokenHandler = () => {
  const [queryParameters] = useSearchParams();
  const selectedLang = useSelector(
    (state: RootState) => state.language.currentLanguage
  );

  const checkForAPFeatureAccess = (decodedAccessToken: any) => {
    const isAPVisible = decodedAccessToken?.Permission?.some(
      (featureObj: any) =>
        featureObj?.f?.toLowerCase() === "approfile" && featureObj?.r === 1
    );
    if (isAPVisible) {
      localStorage.setItem("isAccessibleAPFeature", "true");
    } else {
      localStorage.setItem("isAccessibleAPFeature", "false");
    }
  };
  const checkForHATVFeatureAccess = (decodedAccessToken: any) => {
    const isHATVVisible = decodedAccessToken?.Permission?.some(
      (featureObj: any) =>
        featureObj?.f?.toLowerCase() === "hatv" && featureObj?.r === 1
    );
    if (isHATVVisible) {
      localStorage.setItem("isAccessibleHATVFeature", "true");
    } else {
      localStorage.setItem("isAccessibleHATVFeature", "false");
    }
  };

  const checkForReportBuilderFeatureAccess = (decodedAccessToken: any) => {
    const isReportVisible = decodedAccessToken?.Permission?.some(
      (featureObj: any) =>
        featureObj?.f?.toLowerCase() === "reportbuilder" && featureObj?.r === 1
    );
    if (isReportVisible) {
      localStorage.setItem("isAccessibleReportBuilderFeature", "true");
    } else {
      localStorage.setItem("isAccessibleReportBuilderFeature", "false");
    }
  };

  useEffect(() => {
    const getTokenExchange = () => {
      const lgsAuthorizationCode = queryParameters.get("code");
      const id = queryParameters.get("id");
      const url = `${config.appUrl}/auth/token?code=${lgsAuthorizationCode}&id=${id}`;

      try {
        axiosInstance.get(url).then((res) => {
          if (res && res.data && res.data.statusCode === HttpStatusCode.Ok) {
            const token = authService.handleTokenResponse(res.data);
            const decodedAccessToken = authService.decodeAccessToken(token);
            localStorage.setItem(
              "userAffiliateCode",
              decodedAccessToken?.AffiliateCode ?? ""
            );
            localStorage.setItem(
              "userCountryCode",
              decodedAccessToken?.CountryCode ?? ""
            );
            localStorage.setItem(
              "userRoleType",
              JSON.stringify(decodedAccessToken?.PermissionGroup ?? [])
            );
            if (token) {
              checkForAPFeatureAccess(decodedAccessToken);
              checkForHATVFeatureAccess(decodedAccessToken);
              checkForReportBuilderFeatureAccess(decodedAccessToken);
              const userNativeId = localStorage.getItem("auth-nativeId");
              const userRoleType = JSON.parse(localStorage.getItem("userRoleType") || "[]");
              const identifyByUtag = {
                user_aiu: userNativeId?.toLowerCase(),
                user_role: userRoleType,
              };
          
              withUtag().then((utag) => utag.view(identifyByUtag));

              const siteCountryCode = localStorage.getItem("userCountryCode");
              const siteTags = {
                site_country: siteCountryCode
                  ? countryMappings[siteCountryCode]
                  : "",
                site_currencyCode: siteCountryCode
                  ? currencyMappings[siteCountryCode]
                  : "",
                site_language: selectedLang,
              };

              window.utag_data = siteTags;

              window.location.href = "/myaccount";
            } else {
              window?.heap?.resetIdentity();
              window.location.href = "/login";
            }
          }
        });
      } catch (err) {
        console.log(`Get token exchange error: ${err}`);
      }
    };

    getTokenExchange();
  }, []);

  return <div>Loading.....</div>;
};
