import React, { useEffect, useState } from "react";
import styles from "./AwardQualification.module.scss";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../redux/hooks";
import dropdownArrowLight from "../../../../assets/images/LightTheme/down_arrow_light.svg";
import dropdownArrowDark from "../../../../assets/images/DarkTheme/down_arrow_dark.svg";
import { map } from "lodash";
import { formatDatetoLong } from "../../../../utils/common";
import { useGetAwardHistoryQuery } from "../../../../redux/services/awardHistoryAPI";
import { useProfileParams } from "../../../../utils/common";
import { getAwardNameFromCode } from "../../../../utils/awardIconMapping";

const AwardQualification = () => {
  const { t } = useTranslation();
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const dropdownArrow =
    currentTheme === "light" ? dropdownArrowLight : dropdownArrowDark;
  const [isContentVisible, setIsContentVisible] = useState<boolean>(true);
  const { affiliateCode, aboNumber } = useProfileParams();

  const handleDropdownClick = () => {
    setIsContentVisible((prevState) => !prevState);
  };

  const { data: AwardHistoryResponse, isError } = useGetAwardHistoryQuery(
    {
      affiliateCode,
      aboNumber,
    },
  );

  const closingMonthFormatted = AwardHistoryResponse?.body?.closingMonth
    ? `${AwardHistoryResponse?.body?.closingMonth.slice(-2)}/${AwardHistoryResponse?.body?.closingMonth.slice(0, 4)}`
    : "-";

  const getFormattedYear = (year: number | null) => year ? `PY ${year}` : "-";
  const highestAwardInMarket = AwardHistoryResponse?.body?.awardSummary?.inMarket?.highestAward;
  const highestAwardGar = AwardHistoryResponse?.body?.awardSummary?.gar?.highestAward;

  return (
    <div
      className={`qualificaiton_info_wrapper ${styles.qualificaiton_info_wrapper}`}
    >
      <div
        className={styles.section_title_wraper}
        style={{
          borderBottom: !isContentVisible
            ? `1px solid ${currentTheme === "light" ? "#E4E4E4" : "#282828"}`
            : "",
        }}
      >
        <label
          className={`content_inner_label ${styles.section_label}`}
          data-testid="contact_card_section_heading_testID"
        >
          <div className={styles.main_label}>
            {t("award_history")}
          </div>
          {isContentVisible && (
            <div className={`sub_label ${styles.sub_label}`}>{t("Last_closed_month")} {closingMonthFormatted}</div>
          )}
        </label>
        <img
          src={dropdownArrow}
          alt="Dropdown Arrow"
          className={`dropdown_icon ${styles.dropdown_icon} ${isContentVisible ? styles.rotate : ""}`}
          onClick={handleDropdownClick}
          style={{
            cursor: true ? "pointer" : "default",
          }}
        />
      </div>
      {isContentVisible && (
        <div
          className={`qualification_main_wrapper ${styles.qualification_main_wrapper}`}
        >
          <div className={styles.inner_wrapper}>
            <div className={styles.milstone_wrapper}>
              <div className={styles.milstone_row}>
                <div className={styles.milstone_item}>
                  <span className={styles.item_key}>{t("entry_date")}:</span>
                  {formatDatetoLong(AwardHistoryResponse?.body?.awardSummary?.entryDate)}
                </div>
                <div className={styles.milstone_item}>
                  <span className={styles.item_key}>{t("primary_market")}:</span>
                  {AwardHistoryResponse?.body?.awardSummary?.primaryMarket ? AwardHistoryResponse?.body?.awardSummary?.primaryMarket : "-"}
                </div>
              </div>
              <div className={styles.milstone_row}>
                <div className={styles.milstone_item}>
                  <span className={styles.item_key}>
                    {t("highest_in_market")}:
                  </span>
                  <span className={styles.award_year}>
                    {getFormattedYear(highestAwardInMarket?.firstAchievedPerformanceYear)}
                  </span>
                  <span>{highestAwardInMarket?.awardCode ?
                    getAwardNameFromCode(highestAwardInMarket?.awardCode, t) || "-" : "-"}</span>
                </div>
                <div className={styles.milstone_item}>
                  <span className={styles.item_key}>{t("gar_highest")}:</span>
                  <span className={styles.award_year}>
                    {getFormattedYear(highestAwardGar?.firstAchievedPerformanceYear)}
                  </span>
                  <span>{highestAwardGar?.awardCode ?
                    getAwardNameFromCode(highestAwardGar?.awardCode, t) || "-" : "-"}</span>
                </div>
              </div>
            </div>
            <div className={`award_tbl_wrapper ${styles.award_tbl_wrapper}`}>
              <table className={styles.award_table}>
                <thead className={styles.tbl_head_wrapper}>
                  <tr className={`tbl_head ${styles.tbl_head}`}>
                    <th>{t("award_milstone")}</th>
                    <th>{t("first_time_qualification")}</th>
                  </tr>
                </thead>
                <tbody className={`tbl_body ${styles.tbl_body}`}>
                  {map(AwardHistoryResponse?.body?.awardMilestone, (row, index: any) => (
                    <tr
                      key={index}
                      className={`tbl_row ${styles.tbl_row} ${index % 2 === 0 ? `even_row ${styles.even_row}` : `odd_row ${styles.odd_row}`}`}>

                      <td>{row?.awardCode ? getAwardNameFromCode(row?.awardCode, t) || "-" : "-"}</td>
                      <td>{getFormattedYear(row?.firstAchievedPerformanceYear)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <table className={`${styles.award_table} ${styles.three_col_tbl}`}>
                <thead className={styles.tbl_head_wrapper}>
                  <tr className={`tbl_head ${styles.tbl_head}`}>
                    <th>{t("Year")}</th>
                    <th>{t("in_market")}</th>
                    <th>{t("gar")}</th>
                  </tr>
                </thead>
                <tbody className={`tbl_body ${styles.tbl_body}`}>
                  {AwardHistoryResponse?.body?.highestAwardHistory?.inMarket?.map((inMarketAward: any, index: any) => {
                    const garAward = AwardHistoryResponse?.body?.highestAwardHistory?.gar?.[index];
                    return (
                      <tr
                        key={index}
                        className={`tbl_row ${styles.tbl_row} ${index % 2 === 0 ? `even_row ${styles.even_row}` : `odd_row ${styles.odd_row}`}`}>

                        <td>{getFormattedYear(inMarketAward?.performanceYear)}</td>
                        <td>
                          <div className={styles.award_name_container}>
                            {inMarketAward?.awardCode ?
                              getAwardNameFromCode(inMarketAward?.awardCode, t) || "-" : "-"}
                            {inMarketAward?.trackingStatusName && (
                              <span className={`tracking_tag ${styles.tracking_tag}`}>
                                {inMarketAward?.trackingStatusName}
                              </span>
                            )}
                          </div>
                        </td>
                        <td>
                          <div className={styles.award_name_container}>
                            {garAward?.awardCode ?
                              getAwardNameFromCode(garAward?.awardCode, t) || "-" : "-"}
                            {garAward?.trackingStatusName && (
                              <span className={`tracking_tag ${styles.tracking_tag}`}>
                                {garAward?.trackingStatusName}
                              </span>
                            )}
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AwardQualification;
