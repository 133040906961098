import React, { useState, ReactNode } from "react";
import Header from "./common/Header/Header";
import Footer from "./common/Footer/Footer";
import styles from "./Layout.module.scss";
import { useAppSelector,useAppDispatch } from "../redux/hooks";
import MainSidebar from "./common/MainSidebar/MainSidebar";
import LoginHeader from "./common/LoginHeader/LoginHeader";
interface LayoutProps {
  children: ReactNode; // Use ReactNode for more flexibility,
  isLoginPage?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, isLoginPage = false }) => {
  const dispatch = useAppDispatch();
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const isExpandedLayout = useAppSelector((state) => state.sideNavExpansion.isExpanded);
  return (
    <div
      className={`layout_container ${styles.layout_container} ${currentTheme} ${isLoginPage ? styles.login_page : ''}`}
    >
      {!isLoginPage && <MainSidebar
        isLoginPage={isLoginPage}
      />}
      <div
        className={`main_container ${styles.main_container} ${isExpandedLayout && styles.expanded_sidenav}`}
      >
        <div className={`main_wrapper ${styles.main_wrapper} ${isLoginPage ? styles.login_page : ''}`}
        >
          {isLoginPage
            ? <LoginHeader /> :
            <Header
              isLoginPage={isLoginPage}
              isExpandedLayout={isExpandedLayout}
            />}
          <main
            className={`main_layout_content ${styles.main_layout_content}`}
            style={{ paddingTop: isLoginPage ? "96px" : "", paddingLeft: isLoginPage ? "0" : "", marginTop : isLoginPage ? "auto" : ""}}
          >
            {children}
          </main>
          <Footer isLoginPage={isLoginPage} />
        </div>
      </div>
    </div>
  );
};

export default Layout;
