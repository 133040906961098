import React from "react";
import styles from "./APInfoDetails.module.scss";
import APDocumentsComponent from "./APDocuments/APDocuments";
import APNotesComponent from "./APNotes/APNotes";
import APBackgroundComponent from "./APBackground/APBackground";

interface APInfoDetailsProps {
  onSectionChange: (sectionId: string) => void;
}

const APInfoDetails: React.FC<APInfoDetailsProps> = ({
}) => {
  return (
    <div className={styles.ap_info_details_container}>
      <div className={`content_inner ${styles.inner_wrapper}`}>
        <div className={styles.section_wrapper}>
          <APBackgroundComponent />
        </div>
        <div className={styles.section_container}>
          <div className={styles.section_wrapper}>
            <APNotesComponent />
          </div>
          <div className={styles.section_wrapper}>
            <APDocumentsComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default APInfoDetails;
