import React from "react";
import styles from "./GoalAndActionPlans.module.scss";
import Goals from "./Goals/Goals";
import ProgressNotes from "./ProgressNotes/ProgressNotes";
import GoalActionPlanDocument from "./Documents/GoalActionPlanDocument";

interface GoalAndActionPlansProps {}

const GoalAndActionPlans: React.FC<GoalAndActionPlansProps> = ({}) => {
  return (
    <div className={`GAP_wrapper ${styles.GAP_wrapper}`} data-testid="goals_action_plan_testID">
      <div className={`content_inner ${styles.GAP_inner_wrapper}`}>
        <div className={styles.section_wrapper}>
          <Goals />
        </div>
        <div className={`section_divider ${styles.section_divider}`}></div>
        <div className={styles.section_wrapper}>
          <GoalActionPlanDocument />
        </div>
        {/* <div className={styles.section_wrapper}>
          <ProgressNotes />
        </div> */}
      </div>
    </div>
  );
};

export default GoalAndActionPlans;
