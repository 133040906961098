import React, { useEffect, useState } from "react";
import styles from "./Footer.module.scss";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { footerLinks } from "../../../constants/dataConstants";
import { useAppSelector } from "../../../redux/hooks";
import map from "lodash/map";
import { useGetOptionsListQuery } from "../../../redux/services/optionMasterAPI";
import {
  getFilteredDynamicLinkList,
  getFilteredStaticLinkList,
} from "../../../utils/externalNavigationLinkUtil";
import { countryMappings } from "../../../constants/dataConstants";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import EditIconLight from "../../../assets/images/edit_link_light.svg";
import EditIconDark from "../../../assets/images/edit_link_dark.svg";

interface FooterLink {
  privacyPolicy: string;
  termsConditions: string;
}

interface FooterLinks {
  [key: string]: FooterLink;
}

type FooterProps = {
  isLoginPage?: boolean;
};

const Footer: React.FC<FooterProps> = ({ isLoginPage = false }) => {
  const { i18n, t } = useTranslation();
  const history = useLocation();
  const userDetails = useAppSelector((state: any) => state.userDetails.user);
  const selectedLang = useSelector(
    (state: any) => state.language.currentLanguage
  );
  const currentTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  const editIcon = currentTheme === "light" ? EditIconLight : EditIconDark;
  const [userAffiliateCode] = useState(userDetails?.userAffiliateCode || 0);
  const links = (footerLinks as FooterLinks)[userAffiliateCode] || {};

  const [externalSitesList, setExternalSitesList] = useState<
    externalSiteTypeFomAPI[] | undefined
  >([]);
  const [staticExternalSitesList, setStaticExternalSitesList] = useState<
    externalSiteTypeFomAPI[] | undefined
  >([]);
  const [dynamicExternalSitesList, setDynamicExternalSitesList] = useState<
    externalSiteTypeFomAPI[] | undefined
  >([]);

  const { data } = useGetOptionsListQuery(
    {
      applicationCode: "amw",
      optionMasterName: "siteheader-externalsite",
    },
    { skip: isLoginPage }
  );

  useEffect(() => {
    const resData = data?.body?.options;
    setStaticExternalSitesList(
      getFilteredStaticLinkList(resData, userDetails?.userAffiliateCode)
    );
    setDynamicExternalSitesList(
      getFilteredDynamicLinkList(resData, userDetails.affiliateCode)
    );
  }, [data, userDetails, userDetails.affiliateCode]);

  const appendUTMParams = (url: any, utmParams: any) => {
    const urlObj = new URL(url);
    const params = new URLSearchParams(utmParams);

    params.forEach((value, key) => {
      urlObj.searchParams.append(key, value);
    });

    return urlObj.toString();
  };

  useEffect(() => {
    if (history.pathname === "/profile") {
      setExternalSitesList(dynamicExternalSitesList);
    } else {
      setExternalSitesList(staticExternalSitesList);
    }
  }, [history, staticExternalSitesList, dynamicExternalSitesList]);

  const handleClickExternalLink = (
    clickedSiteLinkObj: externalSiteTypeFomAPI
  ) => {
    let siteLink = "";

    if (clickedSiteLinkObj?.hasParameter) {
      siteLink = clickedSiteLinkObj?.code
        ?.replace("{affiliateCode}", userDetails.affiliateCode)
        ?.replace("{aboNumber}", userDetails.aboNumber)
        ?.replace("{aboNumberBase64}", window.btoa(userDetails.aboNumber))
        ?.replace("{locale}", i18n.language);
    } else {
      siteLink = clickedSiteLinkObj?.code ?? "";
    }

    const userNativeId = localStorage.getItem("auth-nativeId");

    const utmCampaign = `${countryMappings[userDetails.userAffiliateCode]}_${selectedLang}_${userNativeId}`;
    const utmTrackingCode = `?utm_source=am_workspace&utm_medium=linkout&utm_campaign=${utmCampaign}`;
    const finalURL =
      clickedSiteLinkObj?.systemCode === "boss"
        ? siteLink
        : appendUTMParams(siteLink, utmTrackingCode);

    window.open(finalURL, "_blank");
  };

  return (
    <footer
      className={`footer_container ${styles.footer_container}`}
      data-testid="footer_component_testId"
    >
      <div className={styles.footer_content}>
        <span className={styles.content}>{t("copyright")}</span>
        <span className={styles.links}>
          <a
            href={links.privacyPolicy}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("privacy_policy")}
          </a>
          <span> | </span>
          <a
            href={links.termsConditions}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("terms_conditions")}
          </a>
        </span>
      </div>
      {!isLoginPage && (
        <ul className={`navlist ${styles.navlist_left}`}>
          {map(externalSitesList, (siteLinkObj: externalSiteTypeFomAPI) => (
            <li
              key={siteLinkObj.code}
              className={styles.nav_item}
              onClick={() => handleClickExternalLink(siteLinkObj)}
            >
              <span className={`label ${styles.label}`}>
                {t(`header.${siteLinkObj?.value}`)}
              </span>
              <img src={editIcon} alt={editIcon} />
            </li>
          ))}
        </ul>
      )}
    </footer>
  );
};

export default Footer;
