import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./LegAnalysis.module.scss";
import { useAppSelector } from "../../../../redux/hooks";
import ErrorInPageScreen from "../../../common/ErrorInPageScreen/ErrorInPageScreen";
import BusinessInfoTabel from "../BusinessINformationTables/BusinessInfoTable";
import { SortOrder, IColumnState } from "../../../../types/types";
import { useGetLegAnalysisListQuery } from "../../../../redux/services/legAnalysisAPI";
import { sortAccountTableData } from "../../../../utils/common";
import MyAccountsLoader from "../../../common/Loaders/MyAccountsLoader/MyAccountsLoader";
import { isEmpty } from "lodash";
import { isValidResponse } from "../../../../utils/validateAPIResponse";
import KPIsAndTilesSection from "../KPIsAndTilesSection/KPIsAndTilesSection";
import SeperatorLineDark from "../../../../assets/images/DarkTheme/seperator_line.svg";
import { useGetKpiSettingsQuery } from "../../../../redux/services/kpiSettingsByTypeAPI";
import KPIsListDataMassager from "../../../CustomHooks/useGetMassagedKPIsList";
import { useProfileParams } from "../../../../utils/common";
interface ILegAnalysisTableRow {
  affiliateCode: string;
  aboNumber: string;
  name: string;
  localName: string | null;
  marketCountryCode: string;
  marketName: string;
  currentAward: {
    awardCode: string;
    awardName: string;
  };
  trackingAward: {
    awardCode: string;
    awardName: string;
    awardTagCode: number;
    awardTagName: string;
  };
  monthlyRevenue: {
    revenue: number;
    revenuePerformancePercentage: string | number;
    localRevenue: number;
    localRevenueCurrency: string;
    localRevenuePerformancePercentage: string | number;
  };
  monthlyContributor: {
    contributor: number;
    contributorPerformancePercentage: string | number;
  };
  annualRevenue: {
    revenue: number;
    revenuePerformancePercentage: string | number;
    localRevenue: number;
    localRevenueCurrency: string;
    localRevenuePerformancePercentage: string | number;
  };
  annualContributor: {
    contributor: number;
    contributorPerformancePercentage: string | number;
  };
}

const LegAnalysis: React.FC<CategoriesProps> = ({ category }) => {
  const defaultSortState = {
    name: "nosort" as SortOrder,
    "currentAward.awardName": "nosort" as SortOrder,
    "trackingAward.awardName": "nosort" as SortOrder,
    "monthlyRevenue.revenue": "desc" as SortOrder,
    "annualRevenue.revenue": "nosort" as SortOrder,
    "monthlyContributor.contributor": "nosort" as SortOrder,
    "annualContributor.contributor": "nosort" as SortOrder,
  };
  const { t } = useTranslation();
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const userDetails = useAppSelector((state: any) => state.userDetails.user);
  const [closingMonth, setClosingMonth] = useState<string>("");

  const [tableDataList, setTableDataList] = useState<any[] | []>([]); // TODO: ILegAnalysisTableRow >> add all type of expected object[with all expected column of keys values] for the table
  const [columnState, setColumnState] =
    useState<IColumnState>(defaultSortState);
  const [isAPIError, setIsAPIError] = useState<boolean>(false);
  const { affiliateCode, aboNumber } = useProfileParams();
  
  const {
    isFetching,
    isLoading: isLoadingLegAnalysis,
    isError,
    data: legAnalysisData,
  } = useGetLegAnalysisListQuery(
    {
      affiliateCode,
      aboNumber,
    },
    {
      skip: isAPIError,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (!isEmpty(legAnalysisData)) {
      if (isValidResponse(legAnalysisData?.statusCode)) {
        const intialSortData = getSortedData();
        setTableDataList(intialSortData);
      } else {
        handleAPIError();
      }
    }
  }, [legAnalysisData, columnState, isFetching, userDetails]);

  const handleAPIError = () => {
    setIsAPIError(true);
    setTableDataList([]);
    setClosingMonth("");
  };

  useEffect(() => {
    if (isError) handleAPIError();
  }, [isError]);

  const getSortedData = () => {
    let sortedData = legAnalysisData?.body?.legs; //data?.body?.legs || [];
    let closingMonthsFormatted = legAnalysisData?.body?.closingMonth
      ? `${legAnalysisData?.body?.closingMonth.slice(-2)}/${legAnalysisData?.body?.closingMonth.slice(0, 4)}`
      : "";
    setClosingMonth(closingMonthsFormatted); // data?.body?.closingMonth
    for (const [column, order] of Object.entries(columnState)) {
      if (order !== "nosort") {
        sortedData = sortAccountTableData(sortedData, column, order);
        break; // Only sort by one column at a time
      }
    }

    return sortedData;
  };

  // Add new table if you want to display
  const tableColumnData = [
    t("Account_table_headers.Leg_Name"),
    t("Account_table_headers.Monthly_Revenue"),
    t("Account_table_headers.Monthly_Contributors"),
    t("Account_table_headers.Annual_Revenue YTD"),
    t("Account_table_headers.Annual_Contributors"),
  ];
  // Maintain order for the respective column of tableColumnData
  // add all expected table key path for the respective column to be sorted
  const tableColumnNameMapper = [
    "name",
    "monthlyRevenue.revenue",
    "monthlyContributor.contributor",
    "annualRevenue.revenue",
    "annualContributor.contributor",
  ];

  const [structureKPIsList, setStructureKPIsList] = useState<any>([]);
  const massagedKPIslist = KPIsListDataMassager(structureKPIsList ?? []);

  const { data: structureKpisResponse } = useGetKpiSettingsQuery({
    type: "business-performance-structure",
    affiliateCode
  },
    { refetchOnMountOrArgChange: true });

  useEffect(() => {
    const tempKPIslist = structureKpisResponse?.body;
    setStructureKPIsList(tempKPIslist);
  }, [structureKpisResponse]);

  return (
    <div className={`legAnalysis_wrapper ${styles.legAnalysis_wrapper}`}>
      <div className={styles.heading_area}>
        <div
          className={`${styles.heading}`}
          data-testid="leg_analysis_bg_heading_testID"
        >
          {t("structure")}
        </div>
        <div className={`section_sub_heading ${styles.sub_heading}`}>
          {t("structure_summary")}
        </div>
      </div>
      <KPIsAndTilesSection
        tileDataList={massagedKPIslist}
        kpiList={massagedKPIslist}
        SeperatorIcon={SeperatorLineDark}
        category={category}
      />
      <div className={styles.heading_area}>
        <div
          className={`${styles.heading} ${styles.small_heading}`}
          data-testid="leg_analysis_bg_heading_testID"
        >
          {t("leg_analysis")}
        </div>
        {closingMonth && !isEmpty(tableDataList) && (
          <div className={`section_sub_heading ${styles.sub_heading}`}>
            {closingMonth ? `${t("Last_closed_month")} ${closingMonth}` : ""}
          </div>
        )}
      </div>
      {isFetching || isLoadingLegAnalysis ? (
        <MyAccountsLoader num={6} />
      ) : isAPIError ? (
        <ErrorInPageScreen
          handleClickTryAgain={() => {
            setIsAPIError(false);
          }}
        />
      ) : (
        <div className={`legAnalysis_section ${styles.legAnalysis_section}`}>
          <BusinessInfoTabel
            tableData={tableDataList}
            isLoading={false}
            columnState={columnState}
            setColumnState={setColumnState}
            tableColumnData={tableColumnData}
            tableColumnNameMapper={tableColumnNameMapper}
            rowStepperLimit={15}
            isSortable={false}
          />
        </div>
      )}
    </div>
  );
};

export default LegAnalysis;
