import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import styles from "./UploadedDocView.module.scss";
import { useAppSelector } from "../../../redux/hooks";
import { translateKey } from "../../../utils/common";
import Dropdown from "../Dropdown/Dropdown";
import Button from "../Button/Button";
import { SuccessionPlanFileType, toastMessageType } from "../../../types/types";
import formatFileSize from "../../../utils/formatFileSize";
import Spinner from "../Spinner/Spinner";
import deleteIconLight from "../../../assets/images/LightTheme/delete_light.svg";
import deleteIconDark from "../../../assets/images/DarkTheme/delete_dark.svg";
import TextArea from "../TextArea/TextArea";
import { massageDocumentTypeList } from "../../../utils/uploadDocumentUtil";
import { useUpdateSuccessionPlanMutation } from "../../../redux/services/successionPlanAPI";
import { useProfileParams } from "../../../utils/common";
interface DocumentType {
  code: number | string;
  value: string;
}

interface UploadedDocumentProps {
  popupTitle?: string;
  file: SuccessionPlanFileType | undefined;
  documentTypeList: DropdownItem[] | undefined;
  selectedDocType?: DocumentType | undefined;
  handleCancelCallback: () => void;
  handleTogglePopupCallback: () => void;
  handleClickDeleteCallback: () => void;
  isEnabledDropdown?: boolean;
  section?: string;
  uploadMutation: any;
  sourceScreenName?: string;
  setToastMessage: (args: string | null) => void;
  setShowToast: (args: boolean) => void;
  setIsSccessUpload: (args: toastMessageType) => void;
  isEditEnabled?: boolean;
  existingNote?: string;
  fileName?: string;
  fileSize?: number;
  aboSuccessionId?: number
}

const UploadedDocView: React.FC<UploadedDocumentProps> = ({
  popupTitle = 'uploadDocument',
  file,
  documentTypeList,
  selectedDocType,
  handleCancelCallback,
  handleTogglePopupCallback,
  handleClickDeleteCallback,
  isEnabledDropdown,
  section,
  uploadMutation,
  sourceScreenName,
  setToastMessage,
  setShowToast,
  setIsSccessUpload,
  isEditEnabled = false,
  existingNote = "",
  fileName = "",
  fileSize = null,
  aboSuccessionId = null
}) => {
  const { t } = useTranslation();
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const userDetails = useAppSelector((state: any) => state.userDetails.user);
  const selectedAP = useAppSelector((state: any) => state.apDetails.selectedAP);
  const selectedYear: string = useAppSelector(
    (state: any) => state.selectedYear.selectedYear
  );
  const deleteIcon =
    currentTheme === "light" ? deleteIconLight : deleteIconDark;

  const [docTypeList, setDocTypeList] = useState<DocumentType[] | []>([]);
  const [documentType, setDocumentType] = useState<DropdownItem | undefined>(selectedDocType);
  const [comment, setComment] = useState<string>(existingNote);
  // const [affiliateCode] = useState(userDetails?.affiliateCode || 0);
  // const [aboNumber] = useState(userDetails?.aboNumber || 0);
  const [isErrorInUpload, setIsErrorInUpload] = useState(false);
  const { affiliateCode, aboNumber } = useProfileParams();
  

  const [uploadDocument, { isLoading: isUploadLoading, isError }] =
    uploadMutation();

  const [updateDocument, { isLoading: isUpdateLoading, isError: isErrorInUpdateDoc }] =
    useUpdateSuccessionPlanMutation();

  useEffect(() => {
    isError && setIsErrorInUpload(true);
  }, [isError]);

  useEffect(() => {
    isErrorInUpdateDoc && setIsErrorInUpload(true);
  }, [isErrorInUpdateDoc])

  useEffect(() => {
    const massagedList = massageDocumentTypeList(documentTypeList);
    setDocTypeList(massagedList);
  }, [documentTypeList]);

  const handleClickCancel = () => {
    setIsErrorInUpload(false);
    handleCancelCallback();
  };

  const updateExistingDoc = () => {
    const formData: any = new FormData();
    formData?.append("aboSuccessionId", aboSuccessionId);
    formData?.append("documentType", documentType?.code ?? "");
    formData?.append("note", comment);
    updateDocument({
      affiliateCode,
      aboNumber,
      payload: formData
    })
      .then((response: any) => {
        if (response?.data?.statusCode === 200) {
          setToastMessage(
            sourceScreenName === "apInformation"
              ? "Document Successfully uploaded."
              : t("item_upload_success")
          );
          setIsSccessUpload("success");
          setIsErrorInUpload(false);
        } else {
          console.error(`Upload failed for the Item`);
          setIsErrorInUpload(true);
          setToastMessage(null);
        }
      })
      .finally(() => {
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
          setIsSccessUpload(undefined);
          setToastMessage(null);
        }, 3000);
        handleTogglePopupCallback();
      });
  }

  const uploadNewDoc = () => {
    setIsErrorInUpload(false);

    const formData: any = new FormData();
    if (section === "succession_plan_doc") {
      formData?.append("documentType", documentType?.code ?? "");
      formData?.append("note", comment);
      formData?.append("file", file?.imgFile);
    } else {
      formData?.append(
        "performanceYear",
        sourceScreenName ? "0" : selectedYear ?? ""
      );
      formData?.append("file", file?.imgFile);
    }

    uploadDocument({
      affiliateCode,
      aboNumber,
      payload: formData,
      ...(selectedAP.code &&
        sourceScreenName === "apInformation" && { apCode: selectedAP.code }),
    })
      .then((response: any) => {
        if (response?.data?.statusCode === 200) {
          setToastMessage(
            sourceScreenName === "apInformation"
              ? "Document Successfully uploaded."
              : t("item_upload_success")
          );
          setIsSccessUpload("success");
          setIsErrorInUpload(false);
        } else {
          console.error(`Upload failed for the Item`);
          setIsErrorInUpload(true);
          setToastMessage(null);
        }
      })
      .finally(() => {
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
          setIsSccessUpload(undefined);
          setToastMessage(null);
        }, 3000);
        handleTogglePopupCallback();
      });
  }

  const handleClickSave = async () => {
    isEditEnabled ? updateExistingDoc() : uploadNewDoc();
  };

  const handleSelectDocType = (type: DropdownItem) => {
    setDocumentType(type);
  };

  const handleChangeComment = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setComment(event.target.value);
  };

  return (
    <section
      className={`uploaded_document_wrapper ${styles.uploaded_document_wrapper}`}
    >
      <div className={`${styles.modal_title}`}>
        {t(translateKey(popupTitle, sourceScreenName))}
      </div>
      <div className={styles.inner_wrapper}>
        {isEnabledDropdown && (
          <div className={styles.dropdown_wrapper}>
            <Dropdown
              dropdownName={t(translateKey("documentType", sourceScreenName))}
              items={docTypeList}
              selectedItem={documentType}
              handleSelectItemCallback={handleSelectDocType}
            />
          </div>
        )}
        {sourceScreenName === 'successionPlan' && <div className={styles.text_area_section}>
          <TextArea
            id={`add_comment_text_area`}
            label={"Add a comment"}
            changeHandler={handleChangeComment}
            value={comment}
          />
        </div>}
        <div className={styles.file_info}>
          <div className={styles.file_info_wrapper}>
            <div className={styles.file_info_inner_wrapper}>
              <div className={`file_name ${styles.file_name}`}>
                {isEditEnabled ? fileName : file?.imgFile?.name ?? ""}
              </div>
              <div
                className={`file_details_wrapper ${styles.file_details_wrapper}`}
              >
                <span className={`file_size ${styles.file_size}`}>
                  {formatFileSize(isEditEnabled ? fileSize : file?.imgFile?.size)}
                </span>
                {(isUploadLoading || isUpdateLoading) && (
                  <>
                    <span className={styles.spinner}>
                      <Spinner />
                    </span>
                    <span className={`uploading_text ${styles.uploading_text}`}>
                      {t(translateKey("uploadingText", sourceScreenName))}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className={styles.delete_icon_wrapper}>
              <img
                src={deleteIcon}
                alt="delete"
                className={styles.delete_icon}
                onClick={() => handleClickDeleteCallback()}
              />
            </div>
          </div>
          {isErrorInUpload && (
            <div className={`error_container ${styles.error_container}`}>
              {t(translateKey("fileUploadErrorMsg", sourceScreenName))}
            </div>
          )}
        </div>
        <div className={styles.btn_wrapper}>
          <Button
            id="cancel_btn"
            onClick={handleClickCancel}
            label={t(translateKey("btnCancel", sourceScreenName))}
            style="unfilled"
          />
          <Button
            id="save_btn"
            onClick={handleClickSave}
            label={t(translateKey("btnSave", sourceScreenName))}
            style="filled"
            isDisabled={
              (isEnabledDropdown && isEmpty(documentType)) || isUploadLoading || isUpdateLoading
            }
          />
        </div>
      </div>
    </section>
  );
};

export default UploadedDocView;
