import React, {useState} from "react";
import styles from "./Benchmark.module.scss";
import { useAppSelector } from "../../../redux/hooks";
import { useTranslation } from "react-i18next";
import Icon from "../Icon/Icon";
import Tooltip from "../Tooltip/Tooltip";
import { CurrencyFormatter } from "../../../utils/currencyFormatter";
import { NumberFormatter } from "../../../utils/NumberFormatter";

type BenchmarkProps = {
  minValue: number | 0;
  maxValue: number | 0;
  midValue?: number | 0;
  quartileLine?: any;
  benchmarkValue: number | 0;
  isPercentValue: boolean | undefined;
  benchmarkDataType?: string;
  currency?: string;
};

const Benchmark: React.FC<BenchmarkProps> = ({
  minValue = 0,
  maxValue = 0,
  midValue = 0,
  quartileLine,
  benchmarkValue = 0,
  isPercentValue = false,
  benchmarkDataType = "",
  currency = "",
}) => {
  const { t } = useTranslation();
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const middleValue = (minValue + maxValue) / 2;
  const benchmarkPercentValue = (benchmarkValue * 100) / maxValue;
  const userDetails = useAppSelector((state: any) => state.userDetails.user);
  const [userAffiliateCode] = useState(userDetails?.userAffiliateCode || 0);


  const shouldMarkerHide = !(Math.abs(minValue) === 0 && Math.abs(maxValue) === 0);
  const getPercentage = (value: number) => ((value - minValue)/ (maxValue - minValue)) * 100;
  const midPos = getPercentage(midValue);
  const quartileArray = quartileLine ?? [];
  const [quartile1, quartile2] = quartileArray;
  const quartile1Pos = getPercentage(quartile1);
  const quartile2Pos = getPercentage(quartile2);
  const markerPos = getPercentage(benchmarkValue);

  return (
    <div className={`benchmark_wrapper ${styles.benchmark_wrapper}`}>
      <div
        className={`benchmark_title_section ${styles.benchmark_title_section}`}
      >
        <span>{t("benchmark")}</span>
        <Tooltip
          content=
          {
            <>
              {t("tooltip.0")}
              <span className={`bold_text ${styles.bold_text}`}>
                {" "}
                {t("tooltip.1")}{" "}
              </span>
              {t("tooltip.2")}
            </>
          }
          
          width="257"
          height="auto"
          bottom="30px"
          left="610%"
        >
          <span>
            <Icon
              name="info_icon"
              size={18}
              color={currentTheme === "light" ? "#707070" : "#AEAEB2"}
            />
          </span>
        </Tooltip>
      </div>
      <div className={`benchmark ${styles.benchmark}`}>
        <div className={`benchmark_track ${styles.benchmark_track}`}>
          <div className={`benchmark_sections ${styles.benchmark_sections}`}>
            {/* Three sections with a middle solid line */}

            {shouldMarkerHide &&
              <>
                {quartile1Pos > 0 ? <div
                  className={`benchmark_section  ${styles.benchmark_section} `}
                  style={{ left: `${quartile1Pos}%`}}
                  title={quartile1}
                ></div> : ""}
                {midValue > 0 ? <div
                  className={`benchmark_section middle ${styles.benchmark_section} ${styles.middle}`}
                  style={{ left: `${midPos}%`}}
                ></div> : ""}
                  {quartile2Pos > 0 ? <div
                  className={`benchmark_section  ${styles.benchmark_section} `}
                  style={{ left: `${quartile2Pos}%` }}
                  title={quartile2}
                ></div> : ""}
              </>
            }
            <div
              className={`benchmark_section ${styles.benchmark_section}`}
            ></div>

          </div>

          {/* Dynamic marker (map pin) */}
          {shouldMarkerHide && <div
            className={`${styles.benchmark_marker}`}
            style={{ left: `${markerPos}%` }}
          >
            <Icon
              name="benchmark_marker"
              size={21}
              color={currentTheme === "light" ? "#5969D6" : "#99B2F5"}
            />
          </div>}
        </div>

        <div className={`${styles.benchmark_labels}`}>
          <span>
            {benchmarkDataType === "currency"
              ? String(CurrencyFormatter(currency, minValue)).replace(/\.00$/, '')
              : benchmarkDataType === "number" ? NumberFormatter(userAffiliateCode, minValue) 
              : benchmarkDataType === "percentage"  ? minValue+"%" : ""
            }
          </span>
          {midValue !== minValue && <span
            className={styles.mid_values}
             style={{ left: `${midPos}%`}}
          >
            {benchmarkDataType === "currency" && midValue !== 0
              ? String(CurrencyFormatter(currency, midValue)).replace(/\.00$/, '')
              : benchmarkDataType === "number" ? NumberFormatter(userAffiliateCode, midValue) 
              : benchmarkDataType === "percentage" && midValue ? midValue+"%" : ""
            }
          </span>}
          <span>
            {benchmarkDataType === "currency" && maxValue !== 0
              ? String(CurrencyFormatter(currency, maxValue)).replace(/\.00$/, '')
              : benchmarkDataType === "number" ? NumberFormatter(userAffiliateCode, maxValue) 
              : benchmarkDataType === "percentage"  ? maxValue+"%" : ""
            }
          </span>
        </div>
      </div>
    </div>
  );
};

export default Benchmark;
