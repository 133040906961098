import React from "react";
import "./RadioButton.module.scss"

interface RadioButtonProps {
  label: string;
  name: string;
  value: string;
  selectedValue: string;
  onChange: (value: string) => void;
}

const RadioButton: React.FC<RadioButtonProps> = ({ label, name, value, selectedValue, onChange }) => {
  return (
    <label className={`radio_option ${selectedValue === value ? "selected" : ""}`}>
      <input
        type="radio"
        name={name}
        value={value}
        checked={selectedValue === value}
        onChange={() => onChange(value)}
      />
      <span className="custom_radio"></span> {label}
    </label>
  );
};

export default RadioButton;
