import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SideNavState {
  isExpanded: boolean;
}

const initialState: SideNavState = {
  isExpanded: false,
};

const sideNavExpansionSlice = createSlice({
  name: "sideNavExpansion",
  initialState,
  reducers: {
    toggleSideNav: (state) => {
      state.isExpanded = !state.isExpanded;
    },
    setSideNavExpanded: (state, action: PayloadAction<boolean>) => {
      state.isExpanded = action.payload;
    },
  },
});

export const { toggleSideNav, setSideNavExpanded } = sideNavExpansionSlice.actions;
export default sideNavExpansionSlice.reducer;
