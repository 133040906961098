import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import styles from "./LinkEditModal.module.scss";
import greenCircleTickLight from "../../../../assets/images/LightTheme/greenCircleTick.svg";
import greenCircleTickDark from "../../../../assets/images/DarkTheme/greenCircleTick.svg";
import closeXLight from "../../../../assets/images/LightTheme/Close_X.svg";
import closeXDark from "../../../../assets/images/DarkTheme/Close_X.svg";

interface FormatterBarProps {
  existingLinkUrl: string;
  linkText?: string;
  linkUrl?: string;
  setLinkText: (textValue: string) => void;
  setLinkUrl: (link: string) => void;
  insertLink: () => void;
  setShowLinkModal: (flag: boolean) => void;
  isValidUrl: boolean;
}



const LinkEditModal: React.FC<FormatterBarProps> = ({
  existingLinkUrl = "",
  linkText = "",
  linkUrl = "",
  setLinkText,
  setLinkUrl,
  insertLink,
  setShowLinkModal,
  isValidUrl
}) => {
  const { t } = useTranslation();
  const currentTheme = useAppSelector((state: RootState) => state.theme.currentTheme);
  const tickImage = currentTheme === "light" ? greenCircleTickLight : greenCircleTickDark;
  const closeImage = currentTheme === "light" ? closeXLight : closeXDark;

  const buttonStylebackgroundColor = currentTheme === "light" ? (linkText && isValidUrl ? "#1C1C1E" : "#D9D9D9") : linkText && isValidUrl ? "#D8D8D9" : "#282828";
  const buttonStyleColor = currentTheme === "light" ? (linkText && isValidUrl ? "#FFFFFF" : "#5E5E5E") : linkText && isValidUrl ? "#000000" : "#8E8E93";

  return (
    <div
      className={`link_modal ${styles.insertLinkModalContainer}`}
    >
      <span className={`link_text ${styles.HeaderText}`}>{existingLinkUrl ? t("edit_Link") : t("insert_Link")}</span>

      <span className={`${styles.HeaderText}`}>{`${t("display_TextCap")}*`}</span>
      <div className={`input_text_outer ${styles.inputLinkOuter}`}>
        <input
          type="text"
          value={linkText}
          onChange={(e: any) => setLinkText(e.target.value)}
          className={`input_text ${styles.inputText}`}
          placeholder={t("Display text")}
        />
      </div>

      <span className={`${styles.HeaderText}`}>{`${t("link_address")}*`}</span>
      <div className={`input_text_outer ${styles.inputLinkOuter}`}>
        <input
          type="text"
          value={linkUrl}
          onChange={(e) => setLinkUrl(e.target.value)}
          className={`input_text ${styles.customInputText}`}
          placeholder={t("enter_address")}
        />
        {linkUrl && <img src={isValidUrl ? tickImage : closeImage} alt={t("valid_link_AP_notes_Edited")}
          className={styles.linkValidationImg}></img>}
      </div>
      <div className={`${styles.buttonContainer}`}>
        <button className={`button_style ${styles.buttonStyles}`} style={{ border: currentTheme === "light" ? "2px solid #2C2C2C" : "2px solid #D8D8D9" }} onClick={() => setShowLinkModal(false)}>{t("cancel_btn")}</button>
        <button className={`${styles.buttonStyles}`}
          style={{
            border: 'none',
            backgroundColor: buttonStylebackgroundColor,
            color: buttonStyleColor,
            cursor: linkText && isValidUrl ? "pointer" : "none",
          }} onClick={linkText && isValidUrl ? insertLink : () => { }} >
          {t("Insert")}
        </button>
      </div>
    </div>
  )
}

export default LinkEditModal;